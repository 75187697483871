import { z } from "zod";
import { ApiImageSchema } from "../image/image-model";

// "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// "name": "string",
// "logo": {
//   "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//   "url": "string"
// },
// "type": "string"

export enum ApiSearchResultType {
    RetailChain = 'RetailChain',
    PointOfSale = 'PointOfSale',
    Manufacturer = 'Manufacturer',
    Product = 'Product',
}

export const ApiSearchResultSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    logo: ApiImageSchema,
    type: z.nativeEnum(ApiSearchResultType),
});

export type ApiSearchResult = z.output<typeof ApiSearchResultSchema>;
