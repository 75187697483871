import { Link, useNavigate } from "react-router-dom";
import Button from "../common/button";
import CtaCard from "../common/cta/cta-card";
import ArrowRightBoldIcon from "../theme/icons/arrow-right-bold-icon";
import { MegaphoneBoldIcon } from "../theme/icons/megaphone-bold-icon";
import { useTranslation } from "react-i18next";
import "./thank-you-page.css";
import ReportCta from "../common/cta/report-cta";
import { FWPage } from "../common/page/page";
import { useMlTranslation } from "../../core/localise/i18n";

const ThankYouPage = () => {
  const {t, mlt} = useMlTranslation();
  const navigate = useNavigate();

  return (
    <FWPage>
      <div className="thank-you-page">
        <div className="thank-you-message">
          <img src={require('./assets/thank-you-image.png')}/>
          <div>
            <h1 className="text-xl-black">{t("thank_you_page.title")}</h1>
            <h2 className="text-lg">
              {mlt("thank_you_page.message")}
            </h2>
          </div>
          <Link to={t("thank_you_page.button_target")} target="_blank">
            <Button
              title={t("thank_you_page.button_label")}
              icon={<ArrowRightBoldIcon className="button-icon" />}
              onClick={() => {}}
            />
          </Link>
        </div>
        <ReportCta />
      </div>
    </FWPage>
  );
}

export default ThankYouPage;