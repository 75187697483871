import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38.61}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M37.2 20.005c0 10.267-8.011 18.582-17.894 18.582S1.413 30.262 1.413 20.005c0-10.257 8.01-18.592 17.893-18.592 9.883 0 17.894 8.325 17.894 18.592Z"
    />
    <path
      fill="#1D1D1B"
      d="M20.48 13.37c.9-.476 3.075-1.083 4.644-.668 4.502 1.183 5.766 4.43 5.978 8.962.263 5.563-3.794 11.025-9.337 10.52-.89-.081-1.688-.486-2.467-.89-.78.414-1.578.809-2.468.89-5.726.516-8.832-5.614-8.72-11.35.09-4.643 2.296-9.033 6.93-9.458 1.223-.11 2.68-.01 3.923.9"
    />
    <path
      fill="#1D1D1B"
      fillRule="evenodd"
      d="M19.306 2.826c-9.05 0-16.48 7.65-16.48 17.18 0 9.527 7.428 17.168 16.48 17.168 9.053 0 16.481-7.632 16.481-17.169 0-9.538-7.43-17.179-16.481-17.179ZM0 20.006C0 9.02 8.593 0 19.306 0c10.715 0 19.307 9.01 19.307 20.005C38.613 31.002 30.02 40 19.306 40 8.594 40 0 30.991 0 20.005Z"
      clipRule="evenodd"
    />
    <path
      fill="#F7A600"
      d="M19.65 15.858c1.174-1.153 2.905-1.993 4.563-1.831 3.307.334 5.381 3.46 5.31 8.213-.07 4.896-4.178 8.538-7.586 8.234-.97-.09-1.943-.526-2.62-1.385-.748.829-1.659 1.305-2.62 1.385-3.409.304-6.503-3.338-6.909-8.153-.414-4.804 2.023-8.952 5.432-9.255 1.497-.132 3.52.96 4.43 2.792Z"
    />
    <path
      fill="#1D1D1B"
      d="M15.837 15.514c-.95.121-1.89 1.072-2.67 2.266-.243.364-.638.455-.961.323-.414-.172-.475-.708-.293-1.102.667-1.416 2.144-2.67 3.753-2.863a.66.66 0 0 1 .758.516c.091.364-.09.81-.587.87v-.01ZM19.145 12.651s.09-1.072 1.425-2.873c1.357-2.316 4.724-3.59 4.724-3.59l-.314 3.56s-1.912-.08-3.114 1.396c-1.123 1.406-1.325 2.721-1.325 2.721"
    />
  </svg>
);
export { SvgComponent as Logo };
