import { z } from "zod";
import { ApiImageSchema } from "../image/image-model";

export const ApiManufacturerSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  logo: ApiImageSchema,
  creationTime: z.coerce.date(),
});

export const ApiAutocompleteProductSchema = z.object({
  name: z.string(),
  brands: z.string(),
  barcode: z.string(),
  matchScore: z.number(),
});

export const ApiProductSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  // TODO: Make non-nullable
  barcode: z.string().nullable(),
  manufacturer: ApiManufacturerSchema,
  images: z.array(ApiImageSchema),
  creationTime: z.coerce.date(),
});

export type ApiProduct = z.output<typeof ApiProductSchema>;
export type ApiAutocompleteProduct = z.output<
  typeof ApiAutocompleteProductSchema
>;
