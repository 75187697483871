import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowRightBoldIcon from "../../../theme/icons/arrow-right-bold-icon";
import { XBoldIcon } from "../../../theme/icons/x-bold-icon";
import { CheckBoldIcon } from "../../../theme/icons/check-bold-icon";
import ContentHeader from "../../content-header";
import { AppLogo } from "../assets/app-logo";
import { Share } from "../assets/share";
import { Logo } from "../assets/logo";
import Button from "../../button";
import "./installer-toast.css";
import { useInstanceState } from "../../../context/instance-state-provider";
import Toast from "../toast";

interface IBeforeInstallPromptEvent extends Event {
  readonly platform: ReadonlyArray<string>;
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

function InstallerToast() {
  const { t } = useTranslation();
  const [showInstructions, setShowInstructions] = useState(false);
  const [showToast, setShowToast] = useInstanceState(
    "showInstallerToast",
    true
  );
  const selfIsPwa = window.matchMedia("(display-mode: standalone)").matches;
  const selfIsMobile = window.matchMedia(
    "(pointer:none), (pointer:coarse)"
  ).matches;

  const [installPrompt, setInstallPrompt] =
    useState<IBeforeInstallPromptEvent | null>(null);
  const handleBeforeInstallPrompt = (event: Event) => {
    event.preventDefault();
    setShowToast(true);
    setInstallPrompt(event as IBeforeInstallPromptEvent);
  };

  useEffect(() => {
    if (!selfIsMobile || selfIsPwa) {
      setShowToast(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [handleBeforeInstallPrompt]);

  const onInstallClick = () => {
    if (!installPrompt) {
      setShowToast(false);
      setShowInstructions(true);
    } else {
      installPrompt.prompt();
    }
  };

  return (
    <>
      {showToast && (
        <Toast
          icon={<Logo />}
          text={t("installer.toast")}
          onClick={onInstallClick}
          onClose={() => setShowToast(false)}
        />
      )}
      {showInstructions && (
        <div className={"installer-pop-up"}>
          <div className="installer-header">
            <ContentHeader
              title={t("installer.instructions_title")}
              icon={<CheckBoldIcon color="var(--color-orange-500)" />}
            />
            <XBoldIcon
              className="close-btn"
              onClick={() => setShowInstructions(false)}
            />
          </div>
          <div className="instructions">
            <div className="item">
              <div className="text-lg">1.</div>
              <div className="text-lg">
                {t("installer.instructions.step_1")}
              </div>
              <Share />
            </div>
            <div className="item">
              <div className="text-lg">2.</div>
              <div className="text-lg">
                {t("installer.instructions.step_2")}
              </div>
            </div>
            <div className="item">
              <div className="text-lg">3.</div>
              <div className="text-lg">
                {t("installer.instructions.step_3")}
              </div>
              <AppLogo />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InstallerToast;
