import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./core/localise/i18n";
import { useEffect, useState } from "react";
import { ApiClient } from "./core/api/api-client";
import DependencyProvider from "./ui/context/dependency-provider";
import { IDependencyContainer } from "./ui/context/dependency-context";
import { DraftReportRepository } from "./core/draft-report/draft-report-repository";
import { ReportRepository } from "./core/report/report-repository";
import { ProductRepository } from "./core/product/product-repository";
import { StatisticsRepository } from "./core/statistics/statistics-repository";
import MobileNavigation from "./ui/menu/mobile-navigation";
import ListView from "./ui/list/list-view";
import MapView from "./ui/map/map-view";
import AddReportView from "./ui/report/add-report-view";
import SelectStatisticsView from "./ui/statistics/select-statistics-view";
import RetailChainPage from "./ui/statistics/retail-chain/retail-chain-page";
import ManufacturerPage from "./ui/statistics/manufacturer/manufacturer-page";
import ThankYouPage from "./ui/thank-you/thank-you-page";
import ReportView from "./ui/report/report-view";
import PointOfSalePage from "./ui/statistics/point-of-sale/point-of-sale-page";
import { FWPage } from "./ui/common/page/page";
import InstanceStateProvider from "./ui/context/instance-state-provider";
import { SearchRepository } from "./core/search/search-repository";
import { useJsApiLoader } from "@react-google-maps/api";
import { QuestionMarkRepository } from "./core/questionmark/questionmark-repository";
import ProductPage from "./ui/statistics/product/ProductPage";

const mapsLibraries: ["places"] = ["places"];
const apiUrl =
  process.env.REACT_APP_API_URL || "https://foodwatch-dev-api.myler.media";
const questionMarkApiUrl = "https://api-c.thequestionmark.org";

function App() {
  const { isLoaded } = useJsApiLoader({
    id: "foodwatch-pwa",
    googleMapsApiKey: "AIzaSyA2Uk2-aI6CSDBAgHxCzH_qixZ1XuTmaCE",
    libraries: mapsLibraries,
  });
  const [dependencies, setDependencies] = useState<IDependencyContainer | null>(
    null
  );

  useEffect(() => {
    const apiClient = new ApiClient(apiUrl);
    const qmClient = new ApiClient(questionMarkApiUrl);

    setDependencies({
      apiClient,
      qmClient,
      qmRepository: new QuestionMarkRepository(qmClient),
      draftReportRepository: new DraftReportRepository(apiClient),
      reportRepository: new ReportRepository(apiClient),
      productRepository: new ProductRepository(apiClient),
      statisticsRepository: new StatisticsRepository(apiClient),
      searchRepository: new SearchRepository(apiClient),
    });
  }, []);

  if (!(dependencies && isLoaded)) {
    // TODO: Show loader / splash
    return <></>;
  }

  return (
    <div className="foodwatch">
      <InstanceStateProvider>
        <DependencyProvider {...dependencies}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MapView />} {...{ yeet: "foo" }} />
              <Route path="/menu" element={<MobileNavigation />} />
              <Route path="/list" element={<ListView />} />
              <Route path="/report" element={<AddReportView />} />
              <Route path="/report/:id" element={<ReportView />} />
              <Route path="/statistics" element={<SelectStatisticsView />} />
              <Route
                path="/statistics/retail-chain/:id"
                element={<RetailChainPage />}
              />
              <Route
                path="/statistics/manufacturer/:id"
                element={<ManufacturerPage />}
              />
              <Route
                path="/statistics/point-of-sale/:id"
                element={<PointOfSalePage />}
              />
              <Route path="/statistics/product/:id" element={<ProductPage />} />
              <Route path="/thank-you" element={<ThankYouPage />} />
              {/* TODO: implement 404 */}
              <Route
                path="*"
                element={<FWPage>Pagina niet gevonden.</FWPage>}
              />
            </Routes>
          </BrowserRouter>
        </DependencyProvider>
      </InstanceStateProvider>
    </div>
  );
}

export default App;
