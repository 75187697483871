import { useEffect, useState } from "react";

export const useLocationPermission = () => {
  const [locationPermissionState, setLocationPermissionState] = useState<
    PermissionState | undefined
  >();

  useEffect(() => {
    if (!navigator.permissions) {
      setLocationPermissionState(undefined);
      return;
    }
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        setLocationPermissionState(permissionStatus.state);

        permissionStatus.onchange = () => {
          setLocationPermissionState(permissionStatus.state);
        };
      });
  }, []);

  return { locationPermissionState };
};
