import { IApiClient } from "../api/api-client";
import { ApiReport, ApiReportResponse, ApiReportResponseSchema, ApiReportSchema } from "./report-model"; 

export type ReportFilter = {
  filter?: string,
  productId?: string, 
  pointOfSaleId?: string,
  manufacturerId?: string, 
  retailChainId?: string,
  latitude?: number,
  longitude?: number,
  maxDistance?: number,
  minDistance?: number,
  
  skipCount?: number,
  maxResultCount?: number,
};

export interface IReportRepository {
    getReports(filter?: ReportFilter, getAll?: boolean): Promise<ApiReport[]>;
    getReportDetails(id: string): Promise<ApiReport>;
}

export class ReportRepository implements IReportRepository {
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }
  
  async getReports(filters: ReportFilter = {}, getAll: boolean = false) {
    return await this.apiClient.requestItems({
        method: "GET",
        endpoint: "/api/app/report",
        body: {
          sorting: 'creationTime desc',
          maxResultCount: 5,
          ...filters,
        }
    }, ApiReportSchema, getAll);
  }

  async getReportDetails(id: string): Promise<ApiReport> {
    return await this.apiClient.request({
        method: "GET",
        endpoint: `/api/app/report/${id}`,
    }, ApiReportSchema);
  }
}