import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DependencyContext from "../context/dependency-context";
import { ApiReport } from "../../core/report/report-model";
import ArrowRightBoldIcon from "../theme/icons/arrow-right-bold-icon";
import { MegaphoneBoldIcon } from "../theme/icons/megaphone-bold-icon";
import ContentHeader from "../common/content-header";
import { GraphBoldIcon } from "../theme/icons/graph-bold-icon";
import { ExclamationBoldIcon } from "../theme/icons/exclamation-bold-icon";
import { ReportCarousel } from "../common/report-carousel/report-carousel";
import ImageViewer from "./components/image-viewer";
import PriceTagIcon from "./assets/price-tag-icon";
import LocationMarkerIcon from "./assets/location-marker-icon";
import CalendarIcon from "./assets/calendar-icon";
import moment from "moment";
import "./report-view.css";
import { BellBoldIcon } from "../theme/icons/bell-bold-icon";
import ReportCta from "../common/cta/report-cta";
import ShareCta from "../common/cta/share-cta";
import { LoadingIcon } from "../common/loading-icon/loading-icon";
import { FWPage } from "../common/page/page";
import { StatisticsType } from "../../core/statistics/statistics-model";
import { useScreenDetector } from "../../core/utility/use-screen-detector";

const ReportView = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const { reportRepository, statisticsRepository } =
    useContext(DependencyContext);

  const [report, setReport] = useState<ApiReport>();
  const [reportAmount, setReportAmount] = useState<{
    product: number;
    manufacturer: number;
    pointOfSale: number;
  }>({ product: 0, manufacturer: 0, pointOfSale: 0 });
  const [latestReports, setLatestReports] = useState<ApiReport[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isDesktop } = useScreenDetector();

  useEffect(() => {
    if (id) {
      reportRepository
        .getReportDetails(id)
        .then((result) => setReport(result))
        .then(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    if (report) {
      reportRepository
        .getReports({ manufacturerId: report.product.manufacturer.id })
        .then((result) => setLatestReports(result));

      // TODO: Add to api
      reportRepository
        .getReports(
          {
            productId: report.product.id,
            maxResultCount: 1000,
          },
          true
        )
        .then((result) =>
          setReportAmount((prev) => ({
            ...prev,
            product: result.length,
          }))
        );

      // statisticsRepository
      //   .getStatistics(report.product.id, StatisticsType.product)
      //   .then((result) =>
      //     setReportAmount((prev) => ({
      //       ...prev,
      //       product: result.amount
      //     }))
      //   );

      statisticsRepository
        .getStatistics(
          report.product.manufacturer.id,
          StatisticsType.manufacturer
        )
        .then((result) =>
          setReportAmount((prev) => ({
            ...prev,
            manufacturer: result.amount,
          }))
        );

      statisticsRepository
        .getStatistics(report.pointOfSale.id, StatisticsType.pointOfSale)
        .then((result) =>
          setReportAmount((prev) => ({
            ...prev,
            pointOfSale: result.amount,
          }))
        );
    }
  }, [report]);

  return (
    <FWPage>
      <div className="report-overview">
        {report ? (
          <>
            <div className="wrapper">
              <div className="report-section">
                <div className="images">
                  <ImageViewer
                    images={[...report.images, ...report.product.images]}
                  />
                </div>
                <div className="details">
                  <div className="row">
                    <div className="text-2xl-black product-name">
                      {report.product.name}
                    </div>
                    <div className="info-container">
                      <div className="info-column">
                        <div className="info-item text-base">
                          <PriceTagIcon
                            className="info-item-icon"
                            color={"var(--color-orange-500)"}
                          />
                          {report.product.manufacturer.name}
                        </div>
                        <div className="info-item text-base">
                          <LocationMarkerIcon
                            className="info-item-icon"
                            color={"var(--color-orange-500)"}
                          />
                          {report.pointOfSale.retailChain?.name || ""}
                        </div>
                        <div className="info-item text-base">
                          <CalendarIcon
                            className="info-item-icon"
                            color={"var(--color-orange-500)"}
                          />
                          {moment(report.creationTime).format("DD-MM-YYYY")}
                        </div>
                      </div>
                      <div className="info-column text-lg-black reports-label">
                        <BellBoldIcon />
                        {`${reportAmount.product} ${t(
                          reportAmount.product !== 1
                            ? "report.reports_label_plural"
                            : "report.reports_label_singular"
                        )}`}
                      </div>
                    </div>
                    <div className="reporter">
                      <MegaphoneBoldIcon
                        width={40}
                        height={40}
                        color={"var(--color-orange-500)"}
                      />
                      <div className="reporter-row">
                        <div className="text-base-black reporter-name">
                          {report.name}
                        </div>
                        <div className="text-base reporter-title">
                          {t("report.reporter_title")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="text-lg-black title">
                      {t("report.description_title")}
                    </div>
                    <div className="text-base description">
                      {report.description}
                    </div>
                  </div>
                  {!isDesktop && <ShareCta />}
                  <div className="row no-horizontal-padding">
                    <ContentHeader
                      title={t("report.statistics_title")}
                      icon={<GraphBoldIcon color="var(--color-orange-500)" />}
                    />
                    <div className="statistic-content">
                      <div className="text-lg-black statistic-row">
                        {t("report.manufacturer_label")}
                        <div className="item-container">
                          <div className="item-info">
                            <div className="logo">
                              <img
                                src={report.product.manufacturer.logo.url}
                                alt={`${report.product.manufacturer.name} logo`}
                              />
                            </div>

                            <div className="text-base-black name">
                              {report.product.manufacturer.name}
                            </div>
                            <div className="text-lg-black reports">
                              {reportAmount.manufacturer}
                            </div>
                          </div>
                          <NavLink
                            className={"text-base"}
                            to={`/statistics/manufacturer/${report.product.manufacturer.id}`}
                          >
                            {t("report.view_manufacturer_label")}
                            <ArrowRightBoldIcon
                              color={"var(--color-orange-500)"}
                            />
                          </NavLink>
                        </div>
                      </div>
                      <div className="text-lg-black statistic-row">
                        {t("report.point_of_sale_label")}
                        <div className="item-container">
                          <div className="item-info">
                            <div className="logo">
                              <img
                                src={report.pointOfSale.logo.url}
                                alt={`${report.pointOfSale.name} logo`}
                              />
                            </div>

                            <div className="text-base-black name">
                              {report.pointOfSale.name}
                            </div>
                            <div className="text-lg-black reports">
                              {reportAmount.pointOfSale}
                            </div>
                          </div>
                          <NavLink
                            className={"text-base"}
                            to={`/statistics/point-of-sale/${report.pointOfSale.id}`}
                          >
                            {t("report.view_point_of_sale_label")}
                            <ArrowRightBoldIcon
                              color={"var(--color-orange-500)"}
                            />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section bottom-details">
                <div className="row no-horizontal-padding">
                  <ContentHeader
                    title={t("report.similar_manufacturer_title")}
                    icon={
                      <ExclamationBoldIcon color="var(--color-orange-500)" />
                    }
                  />
                  <ReportCarousel reports={latestReports} />
                </div>
                <ReportCta />
              </div>
            </div>
          </>
        ) : isLoading ? (
          <LoadingIcon />
        ) : (
          <div className="error">
            <div className="text-2xl-black">{t("statistics.error_title")}</div>
            <div className="text-lg">{t("statistics.error")}</div>
          </div>
        )}
      </div>
    </FWPage>
  );
};

export default ReportView;
