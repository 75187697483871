import { DeceptionStatistic } from "../../../core/statistics/statistics-model";
import { useTranslation } from "react-i18next";

import "./report-types.css";
import ContentHeader from "../../common/content-header";
import { ReportCarousel } from "../../common/report-carousel/report-carousel";
import { useContext, useEffect, useState } from "react";
import { ApiReport } from "../../../core/report/report-model";
import DependencyContext from "../../context/dependency-context";
import { ExclamationBoldIcon } from "../../theme/icons/exclamation-bold-icon";
import { ReportFilter } from "../../../core/report/report-repository";

interface RecentReportsProps {
    filter: ReportFilter;
}

function RecentReports({ filter }: RecentReportsProps) {
  const { t } = useTranslation();
  const { reportRepository } = useContext(DependencyContext);

  const [latestReports, setLatestReports] = useState<ApiReport[]>([]);

  useEffect(() => {
    if (filter) {
      reportRepository
        .getReports(filter)
        .then((result) => setLatestReports(result));
    }
  }, [filter]);

  return (
    <div className="row recent-row">
      <div className="recent-reports">
        <ContentHeader
          title={t("list_view.recent_reports_title")}
          icon={<ExclamationBoldIcon color="var(--color-orange-500)" />}
        />
        <ReportCarousel reports={latestReports} />
      </div>
    </div>
  );
}

export default RecentReports;
