import React from "react";
import "./cta-card.css";

export interface CtaCardProps {
  className?: string;
  icon: JSX.Element;
  children: string | JSX.Element | JSX.Element[];
}

function CtaCard({ className = "", icon, children }: CtaCardProps) {
  return (
    <div className={`cta-container ${className}`}>
      <div className="cta-card">
        <div className="cta-card-icon">{icon}</div>
        {children}
      </div>
    </div>
  );
}

export default CtaCard;
