import { FoodwatchLogo } from "../common/logo";
import { useNavigate, useLocation, NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowRightBoldIcon from "../theme/icons/arrow-right-bold-icon";
import { XBoldIcon } from "../theme/icons/x-bold-icon";
import "./mobile-navigation.css";
import Button, { ButtonVariant } from "../common/button";
import { FWPage } from "../common/page/page";

function MobileNavigation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const goBack =
    location.key !== "default" ? () => navigate(-1) : () => navigate("/");

  return (
    <FWPage showHeader={false} showFooter={false}>
      <div className="mobile-navigation">
        <div className="wrapper">
          <div className="links">
            <div className="close-btn" onClick={goBack}>
              <XBoldIcon />
            </div>
            <NavLink className="text-2xl-black" to="/" replace>
              {t("header.home_label")}
            </NavLink>
            <NavLink className="text-2xl-black" to="/statistics" replace>
              {t("header.statistics_label")}
            </NavLink>
            <Link
              className="text-2xl-black"
              target="_blank"
              to={t("header.about_target")}
            >
              {t("header.about_label")}
            </Link>
            <Link
              className="text-2xl-black"
              target="_blank"
              to={t("header.contact_target")}
            >
              {t("header.contact_label")}
            </Link>
          </div>
          <NavLink className="text-2xl-black button" to="/report" replace>
            <Button
              title={t("header.add_report_label")}
              icon={<ArrowRightBoldIcon color="var(--color-orange-500)" />}
              variant={ButtonVariant.secondary}
              onClick={() => {}}
            />
          </NavLink>
        </div>
        <div className="logo">
          <FoodwatchLogo />
        </div>
      </div>
    </FWPage>
  );
}

export default MobileNavigation;
