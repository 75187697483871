import Footer from "../footer/footer";
import Header, { HeaderTheme } from "../header/header"

interface FWPageProps {
    children: string | JSX.Element | JSX.Element[];
    showHeader?: boolean;
    showBackButton?: boolean;
    showFooter?: boolean;
    headerTheme?: HeaderTheme;
}

export const FWPage = ({
    children,
    showHeader=true,
    showBackButton=true,
    showFooter=true,
    headerTheme: backButtonTheme=HeaderTheme.Grey, 
}: FWPageProps) => {
    return (<>
        {showHeader && <Header headerTheme={backButtonTheme} showBackButton={showBackButton}/>}
        {children}
        {showFooter && <Footer />}
    </>);
}