import { z } from "zod";

export function createApiResponseSchema<ItemType extends z.ZodTypeAny>(
    itemSchema: ItemType,
) {
    return z.object({
        items: z.array(itemSchema),
        totalCount: z.number().optional(),
    });
}
