import { SVGProps } from "react"
const MapCenterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} {...props}>
    <g fill="#6c6c6c">
        <circle cx={18} cy={18} r={7.23} />
        <path d="M36 16.81h-4.27c-.57-6.66-5.88-11.97-12.54-12.54V0h-2.37v4.27c-6.66.57-11.97 5.88-12.54 12.54H0v2.37h4.27c.57 6.66 5.88 11.97 12.54 12.54v4.27h2.37v-4.27c6.66-.57 11.97-5.88 12.54-12.54h4.27v-2.37ZM18 29.4c-6.3 0-11.41-5.11-11.41-11.41S11.7 6.59 18 6.59 29.41 11.7 29.41 18 24.3 29.41 18 29.41Z" />
    </g>
  </svg>
)
export default MapCenterIcon
