import React from "react";
import "./content-header.css";

interface ContentHeaderProps {
  title: string;
  icon: React.ReactElement;
}

function ContentHeader({ title, icon }: ContentHeaderProps) {
  return (
    <div className="content-header-wrapper">
      <div className="content-header-icon-wrapper">{icon}</div>
      <div className="content-header text-2xl-black">{title}</div>
    </div>
  );
}

export default ContentHeader;
