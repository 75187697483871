import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FoodwatchLogo } from "../logo";
import "./footer.css";

function Footer() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="footer">
      <div className="footer-logo-wrapper">
        <FoodwatchLogo className="footer-logo" />
      </div>
      <div className="footer-links-wrapper">
        <div className="footer-links">
          <NavLink className="text-xl-black" to="/">
            {t("footer.home_label")}
          </NavLink>
          <Link
            className="text-xl-black"
            target="_blank"
            to={t("header.about_target")}
          >
            {t("header.about_label")}
          </Link>
          <Link
            className="text-xl-black"
            target="_blank"
            to={t("header.deception_target")}
          >
            {t("header.deception_label")}
          </Link>
          <Link
            className="text-xl-black"
            target="_blank"
            to={t("header.newsletter_target")}
          >
            {t("header.newsletter_label")}
          </Link>
          <Link
            className="text-xl-black"
            target="_blank"
            to={t("header.contact_target")}
          >
            {t("header.contact_label")}
          </Link>
          <NavLink className="text-xl-black" to="/statistics">
            {t("footer.statistics_label")}
          </NavLink>
        </div>
        <div className="footer-links bottom-links">
          <Link
            className="text-sm-black"
            to={t("footer.privacy_link")}
            target="_blank"
          >
            {t("footer.privacy_link_label")}
          </Link>
          <div className="text-sm">{t("footer.copyright_label")}</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
