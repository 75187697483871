import { z } from "zod";
import { ApiDraftReportSchema } from "../draft-report/draft-report-model";
import { ApiProductSchema } from "../product/product-model";
import { ApiPointOfSaleSchema } from "../point-of-sale/point-of-sale-model";

export const ApiReportSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    description: z.string().nullable(),
    draftReport: ApiDraftReportSchema,
    product: ApiProductSchema,
    pointOfSale: ApiPointOfSaleSchema,
    creationTime: z.coerce.date(),
    images: z.array(z.object({ id: z.string(), url: z.string() })),
});

export const ApiReportResponseSchema = z.object({
    totalCount: z.number(),
    items: z.array(ApiReportSchema),
})

export type ApiReport = z.output<typeof ApiReportSchema>;
export type ApiReportResponse = z.output<typeof ApiReportResponseSchema>;
