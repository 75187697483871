import React, { useEffect, useState } from "react";
import "./bar-graph.css";

interface BarGraphProps {
  items: { label: string; amount: number }[];
}

function BarGraph({ items }: BarGraphProps) {
  const [maxCount, setMaxCount] = useState(0);

  useEffect(() => {
    if (items) {
      const count = Math.max(...items.map(i => i.amount));
      setMaxCount(count);
    }
  }, [items]);

  const calculateWidth = (amount: number): string => {
    let percentage = (70 / maxCount) * amount;
    return `${percentage}%`;
  };

  return (
    <div className="bar-graph">
      {items.map((item, index) => (
        <div className="item-container" key={index}>
          <div className="text-xs-black label">{item.label}</div>
          <div className="bar-container">
            <div
              className="bar"
              style={{ width: calculateWidth(item.amount) }}
            ></div>
            <div className="text-base-black amount">{item.amount}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BarGraph;
