import * as React from "react";
import { SVGProps } from "react";
const ArrowRightBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#16282B"}
      d="M7.349 19.992c1.685-1.598 3.38-3.208 5.157-4.895H1.061c-.55 0-.553-.001-.553-.561 0-1.7.01-3.4-.008-5.098-.005-.406.124-.526.528-.525 3.614.013 7.226.008 10.84.008h.47l.049-.095-5.083-4.833c.113-.126.196-.226.288-.317C8.754 2.51 9.922 1.35 11.08.18c.206-.207.34-.264.581-.024 3.863 3.876 7.734 7.744 11.604 11.613.066.066.122.14.141.163-4.033 4.037-8.054 8.06-12.06 12.067l-3.994-4.006-.003-.002Z"
    />
  </svg>
);
export default ArrowRightBoldIcon;
