import React, { FC, useContext, useState } from "react";

import { createContext } from "react";

interface IInstanteState {
  [key: string]: any;
}

interface IInstanceStateOps {
  state: IInstanteState,
  setState: (setter: (state: IInstanteState) => IInstanteState) => void,
}

const InstanceStateContext = createContext<IInstanceStateOps>(
  {} as IInstanceStateOps
);

interface InstanceStateProviderProps {
  children: React.ReactNode;
}

const InstanceStateProvider: FC<InstanceStateProviderProps> = ({children}) => {
  const [state, setState] = useState<IInstanteState>({});

  return (
    <InstanceStateContext.Provider value={{state, setState}}>
      {children}
    </InstanceStateContext.Provider>
  );
};

export const useInstanceState = <S,>(key: string, initial: S): [S, (val: S) => void] => {
  const { state, setState } = useContext(InstanceStateContext);
  const val = key in state
    ? state[key] as S
    : initial;
  const setInstanceState = (val: S) => {
    setState(prev => ({
      ...prev,
      [key]: val
    }));
  };
  
  return [val, setInstanceState];
};

export default InstanceStateProvider;
