export function buildFormData(formData: FormData, data: any, parentKey?: string) {
    const nest = (key: string, val: any) => {
        buildFormData(formData, val, parentKey ? `${parentKey}[${key}]` : key);
    };
  
    if (data instanceof Array) {
        // This would be the correct way to do this (e.g: image[0]=Blob, image[1]=Blob)
        // But of course the back-end doesn't support that.
        // data.forEach((val, key) => nest(key.toString(), val));

        // So instead we get this (e.g. image=Blob, image=Blob)
        data.forEach((val) => formData.append(parentKey!, val));
    }
    else if (data instanceof Object
      && !(data instanceof Date)
      && !(data instanceof File)
      && !(data instanceof Blob)) {
        Object.entries(data).forEach(([key, val]) => nest(key, val));
    }
    else if (data !== null && parentKey) {
        formData.append(parentKey, data);
    }
}
