import * as React from "react"
import { SVGProps } from "react"
const ChevronDownBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#16282B"
      d="M19.505 4.956c.11.099.197.172.277.252 1.02 1.017 2.035 2.038 3.06 3.05.181.181.23.298.02.51-3.39 3.38-6.775 6.766-10.161 10.152-.058.058-.123.107-.142.124L2 8.491l3.505-3.494c1.399 1.474 5.568 5.376 7.044 6.93l6.956-6.97Z"
    />
  </svg>
)
export default ChevronDownBoldIcon
