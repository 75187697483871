import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#F0F0F0" rx={5} />
    <g fill="#000" clipPath="url(#a)">
      <path d="M20.8 22.475V11.699l2.717 2.698.998-1.005L20.092 9l-4.423 4.393.997 1.004 2.718-2.698v10.776h1.415Z" />
      <path d="M12 30.92h16.183V15.508h-5.26v1.416h3.845v12.58H13.414v-12.58h3.846v-1.416H12V30.92Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M12 9h16.183v21.92H12z" />
      </clipPath>
    </defs>
  </svg>
);
export { SvgComponent as Share };
