import { createContext } from "react";
import { IApiClient } from "../../core/api/api-client";
import { IDraftReportRepository } from "../../core/draft-report/draft-report-repository";
import { IReportRepository } from "../../core/report/report-repository";
import { IProductRepository } from "../../core/product/product-repository";
import { IStatisticsRepository } from "../../core/statistics/statistics-repository";
import { ISearchRepository } from "../../core/search/search-repository";
import { IQuestionMarkRepository } from "../../core/questionmark/questionmark-repository";

export interface IDependencyContainer {
  apiClient: IApiClient;
  qmClient: IApiClient;
  qmRepository: IQuestionMarkRepository;
  draftReportRepository: IDraftReportRepository;
  reportRepository: IReportRepository;
  productRepository: IProductRepository;
  statisticsRepository: IStatisticsRepository;
  searchRepository: ISearchRepository;
}

const DependencyContext = createContext<IDependencyContainer>(
  {} as IDependencyContainer
);

export default DependencyContext;
