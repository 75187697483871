import * as React from "react"
import { SVGProps } from "react"
const ListViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={21}
    fill="none"
    {...props}
  >
    <g>
      <path fill="#16282B" d="M24 .5H7.5v4.74H24V.5Z" />
      <path
        fill="#1F1F1F"
        d="M4.5.5H0v4.74h4.5V.5ZM24 15.5H7.5v4.74H24V15.5ZM4.5 15.5H0v4.74h4.5V15.5ZM24 8.22H7.5v4.739H24v-4.74ZM4.5 8.22H0v4.739h4.5v-4.74Z"
      />
    </g>
  </svg>
)
export default ListViewIcon
