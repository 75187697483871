import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ApiReport } from "../../core/report/report-model";
import DependencyContext from "../context/dependency-context";
import {
  GoogleMap,
  OverlayView,
  OverlayViewF,
  useJsApiLoader,
} from "@react-google-maps/api";
import Supercluster, {
  AnyProps,
  ClusterFeature,
  PointFeature,
} from "supercluster";

import "./map-view.css";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchBar } from "../common/search-bar/search-bar";
import Button from "../common/button";
import ArrowRightBoldIcon from "../theme/icons/arrow-right-bold-icon";
import { ReportCarousel } from "../common/report-carousel/report-carousel";
import MapCenterIcon from "./assets/map-center-icon";
import { FWPage } from "../common/page/page";
import InstallerToast from "../common/toasts/installer-toast/installer-toast";
import { useInstanceState } from "../context/instance-state-provider";
import { XBoldIcon } from "../theme/icons/x-bold-icon";
import { ApiPointOfSale } from "../../core/point-of-sale/point-of-sale-model";
import { ReportFilter } from "../../core/report/report-repository";
import LocationToast from "../common/toasts/location-toast/location-toast";
import { useLocationPermission } from "../../core/utility/use-location-permission";

const defaultCenter = { lat: 52.11, lng: 5.17 };
const defaultZoom = 7;

interface FocusData {
  pointOfSale?: ApiPointOfSale;
  resetZoom?: boolean;
}

const mapOptions: google.maps.MapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControl: false,
  maxZoom: 20,
  minZoom: defaultZoom,
  styles: require("./assets/map-style.json"),
};

const sc = new Supercluster({
  radius: 100,
  maxZoom: mapOptions.maxZoom!,
  reduce: (acc, props) => {
    acc.reports = [...(acc.reports || []), ...(props.reports || [])];
  },
});

function reportsToPoints(reports: ApiReport[]): PointFeature<AnyProps>[] {
  const reportsByPos = reports.reduce((acc, report) => {
    const key = report.pointOfSale.id;

    if (!acc[key]) acc[key] = [];
    acc[key].push(report);

    return acc;
  }, {} as { [key: string]: ApiReport[] });

  let i = 1;

  return Object.entries(reportsByPos).map(
    ([key, reports]) =>
      (i++ && {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            reports[0].pointOfSale.longitude,
            reports[0].pointOfSale.latitude,
          ],
        },
        properties: {
          cluster: false,
          posId: key,
          reports: reports,
        },
      }) as PointFeature<AnyProps>
  );
}

export default function MapView() {
  const navigate = useNavigate();
  const routeLocation = useLocation();
  const { reportRepository } = useContext(DependencyContext);
  const [reports, setReports] = useState<ApiReport[]>([]);
  const [filter, setFilter] = useState<ReportFilter>({});
  const mapRef = useRef<google.maps.Map>();

  const [inCooldown, setInCooldown] = useState(true);
  const [zoom, setZoom] = useInstanceState("mapZoom", defaultZoom);
  const [center, setCenter] = useInstanceState<google.maps.LatLngLiteral>(
    "mapCenter",
    defaultCenter
  );
  const [focusData, setFocusData] = useInstanceState<FocusData>("mapFocused", {
    resetZoom: false,
  });
  const [bounds, setBounds] = useState<GeoJSON.BBox>([0, 0, 0, 0]);
  const [clusters, setClusters] = useState<ClusterFeature<any>[]>();
  const { locationPermissionState } = useLocationPermission();

  useEffect(() => {
    if(!inCooldown) {
      setFocusData({
        resetZoom: true
      });
    }
  }, [routeLocation]);

  useEffect(() => {
    if (!focusData.pointOfSale && focusData.resetZoom) {
      setFilter({});
    }
  }, [focusData]);

  useEffect(() => {
    reportRepository
      .getReports(
        {
          ...filter,
          maxResultCount: 1000,
        },
        true
      )
      .then(setReports);
  }, [reportRepository, filter]);

  useEffect(() => {
    if (!mapRef.current) return;

    sc.load(reportsToPoints(reports));
    setClusters(sc.getClusters(bounds, zoom));
  }, [reports, bounds, zoom]);

  useEffect(() => {
    if (!mapRef.current) return;

    const points = reports
      .map((r) => r.pointOfSale)
      .filter((v, i, s) => s.findIndex((p) => p.id == v.id) === i);

    if (points.length === 1 && filter.pointOfSaleId) {
      handleMarkerClick(points[0].id, points[0].latitude, points[0].longitude);
    } else if (filter.latitude && filter.longitude && filter.maxDistance) {
      const bounds = new google.maps.Circle({
        center: { lat: filter.latitude, lng: filter.longitude },
        radius: filter.maxDistance * 1000,
      }).getBounds();
      if (bounds) {
        mapRef.current.fitBounds(bounds);
      }
    } else if (inCooldown) {
      mapRef.current.setCenter(center);
      mapRef.current.setZoom(zoom);
    } else {
      mapRef.current.setCenter(defaultCenter);
      mapRef.current.setZoom(defaultZoom);
    }
  }, [reports]);

  useEffect(() => {
    if (reports.length > 0 && inCooldown) {
      const timeout = setTimeout(() => {
        setInCooldown(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [reports, inCooldown]);

  useEffect(() => {
    if (!inCooldown && focusData.pointOfSale) {
      setFocusData({
        resetZoom: false,
      });
    }
  }, [clusters?.length]);

  const handleBoundsChanged = () => {
    if (!mapRef.current) return;
    const mapBounds = mapRef.current.getBounds()?.toJSON();
    const newBounds: GeoJSON.BBox = [
      mapBounds?.west || 0,
      mapBounds?.south || 0,
      mapBounds?.east || 0,
      mapBounds?.north || 0,
    ];

    if (
      bounds[0] !== newBounds[0] ||
      bounds[1] !== newBounds[1] ||
      bounds[2] !== newBounds[2] ||
      bounds[3] !== newBounds[3]
    ) {
      setBounds(newBounds);
    }
  };

  const handleCenterChanged = () => {
    if (!mapRef.current) return;
    const mapsCenter = mapRef.current.getCenter();
    const newCenter =
      (mapsCenter && {
        lat: mapsCenter.lat(),
        lng: mapsCenter.lng(),
      }) ||
      defaultCenter;

    if (center.lat != newCenter.lat || center.lng != newCenter.lng) {
      setCenter(newCenter);
    }
  };

  const handleZoomChanged = () => {
    if (!mapRef.current) return;
    const newZoom = mapRef.current.getZoom() || defaultZoom;

    if (zoom != newZoom) {
      setZoom(newZoom);
    }
  };

  const handleMarkerClick = (id: number | string, lat: number, lng: number) => {
    if (typeof id === "number") {
      const expansionZoom = Math.min(sc.getClusterExpansionZoom(id), 20);
      mapRef.current?.setZoom(expansionZoom);
    } else {
      mapRef.current?.setZoom(Math.max(zoom, 14));
    }

    setInCooldown(true);
    mapRef.current?.panTo({ lat, lng });
    mapRef.current?.panBy(0, 142);

    const pointOfSale = reports
      .map((r) => r.pointOfSale)
      .find((pos) => pos.id === id);
    if (pointOfSale) {
      setFocusData({ pointOfSale });
    }
  };

  const handleMapCenterClick = () => {
    if (navigator.geolocation && mapRef.current) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        mapRef.current?.setCenter({
          lat: coords.latitude,
          lng: coords.longitude,
        });
        mapRef.current?.setZoom(10);
      });
    }
  };

  const handleClearClick = () => {
    setFocusData({
      resetZoom: true,
    });
  };

  return (
    <FWPage showFooter={false} showBackButton={false}>
      <div className="map-container">
        <GoogleMap
          onLoad={(map) => {
            mapRef.current = map;
          }}
          onBoundsChanged={handleBoundsChanged}
          onZoomChanged={handleZoomChanged}
          onCenterChanged={handleCenterChanged}
          options={mapOptions}
          mapContainerClassName="google-map"
        >
          {clusters?.map(({ id, geometry, properties }) => {
            const [lng, lat] = geometry.coordinates;
            const { posId, reports } = properties;

            return (
              <OverlayViewF
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                key={`cluster-${id || posId}`}
                position={{ lat, lng }}
              >
                <div
                  className={`marker ${
                    focusData.pointOfSale?.id === (id || posId) ? "active" : ""
                  }`}
                  onClick={() =>
                    handleMarkerClick(
                      (id as number) || (posId as string),
                      lat,
                      lng
                    )
                  }
                >
                  {reports.length.toString()}
                </div>
              </OverlayViewF>
            );
          })}
        </GoogleMap>
        {!focusData.pointOfSale && (
          // TODO: Add translations
          <>
            <SearchBar onFilter={setFilter} />
            <div className="report-button">
              <Button
                title={"Meld misleiding nu!"}
                icon={<ArrowRightBoldIcon />}
                onClick={() => navigate("/report")}
              />
            </div>
            {locationPermissionState === "granted" && (
              <div className="map-center-button" onClick={handleMapCenterClick}>
                <MapCenterIcon />
              </div>
            )}
          </>
        )}
        {focusData.pointOfSale && (
          <div className="report-results">
            <div className="btn-container">
              <div className="text-xl-black point-of-sale-label">
                {focusData.pointOfSale.name}
              </div>
              <Button
                title={""}
                icon={<XBoldIcon width={24} height={24} />}
                onClick={handleClearClick}
              />
            </div>
            <ReportCarousel
              reports={
                reports.filter(
                  (r) => r.pointOfSale.id === focusData.pointOfSale!.id
                ) as ApiReport[]
              }
            />
          </div>
        )}
        <InstallerToast />
        <LocationToast onClick={handleMapCenterClick} />
      </div>
    </FWPage>
  );
}
