import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#1F1F1F"}
      d="m10.923 15.757-2.874-4.269-2.873 4.31-3.739-3.118L4.59 9.357 0 7.75l2.034-4.404 3.763 2.058c0-.365.011-.703-.002-1.04a266.08 266.08 0 0 0-.14-3.093c-.012-.21.064-.271.267-.27 1.423.007 2.848.008 4.27-.001.24-.002.271.101.261.302-.065 1.255-.121 2.51-.18 3.765-.004.082 0 .165 0 .31l3.777-2.064c.049.092.087.154.117.219.595 1.301 1.185 2.604 1.789 3.902.096.207.032.266-.156.333-1.387.493-2.77.995-4.155 1.495-.03.01-.057.028-.124.063l3.142 3.273-3.74 3.16v-.001Z"
    />
  </svg>
);
export { SvgComponent as AsteriskBoldIcon };
