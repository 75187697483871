import { useTranslation } from "react-i18next";
import moment from "moment";
import { useFetcher, useNavigate } from "react-router-dom";
import Button from "../common/button";
import ContentHeader from "../common/content-header";
import CtaCard from "../common/cta/cta-card";
import ArrowRightBoldIcon from "../theme/icons/arrow-right-bold-icon";
import ChevronDownBoldIcon from "../theme/icons/chevron-down-bold-icon";
import { ExclamationBoldIcon } from "../theme/icons/exclamation-bold-icon";
import { MegaphoneBoldIcon } from "../theme/icons/megaphone-bold-icon";
import { PinBoldIcon } from "../theme/icons/pin-bold-icon";
import "./list-view.css";
import { ReportCarousel } from "../common/report-carousel/report-carousel";
import { SearchBar } from "../common/search-bar/search-bar";
import { ApiReport } from "../../core/report/report-model";
import { useContext, useEffect, useState } from "react";
import DependencyContext from "../context/dependency-context";
import ReportCard from "../common/card";
import ReportCta from "../common/cta/report-cta";
import { LoadingIcon } from "../common/loading-icon/loading-icon";
import { FWPage } from "../common/page/page";
import { ReportFilter } from "../../core/report/report-repository";
import { useInstanceState } from "../context/instance-state-provider";

function ListView() {
  const { t } = useTranslation();
  const { reportRepository } = useContext(DependencyContext);

  const [ latestReports, setLatestReports ] = useState<ApiReport[] | null>(null);
  const [ closebyReports, setClosebyReports ] = useState<ApiReport[] | null>(null);
  const [ filter, setFilter ] = useInstanceState<ReportFilter>("searchFilter", {});
  
  const [ dataExhausted, setDataExhausted ] = useState(false);

  const getMoreReports = () => {
    reportRepository.getReports({
        ...filter,
        skipCount: latestReports?.length || 0,
        maxResultCount: 5,
      })
      .then((reports) => {
        setLatestReports([
          ...latestReports || [],
          ...reports, 
        ]);

        if(reports.length < 5) {
          setDataExhausted(true);
        }
      });
  };

  useEffect(() => {
    const getClosebyReports = (coords?: GeolocationCoordinates) => {
      reportRepository.getReports({
        latitude: coords?.latitude || undefined,
        longitude: coords?.longitude || undefined,
        maxDistance: 20,
      })
      .then(reports => {
        setClosebyReports(reports);
      });
    };

    if(closebyReports === null) {
      navigator.geolocation.getCurrentPosition(({coords}) => {
        getClosebyReports(coords);
      }, () => setClosebyReports([]));
    }

    if(latestReports === null) {
      getMoreReports();
    }
  }, [filter, latestReports]);

  const onHandleFilter = (filter: ReportFilter) => {
    setFilter(filter);
    setClosebyReports([]);
    setDataExhausted(false);
    setLatestReports(null);
  };

  const renderList = () => {
    return latestReports?.map((report) => (
      <ReportCard
        key={report.id}
        report={report}
      />
    ));
  };

  return (
    <FWPage showBackButton={false}>
      <div className="list-view">
        <SearchBar onFilter={onHandleFilter}/>
        {closebyReports?.length !== 0 &&
        <div className="recent-reports">
          <ContentHeader
            title={t("list_view.nearby_reports_title")}
            icon={<PinBoldIcon color="var(--color-orange-500)" />}
          />
          {closebyReports 
            ? <ReportCarousel reports={closebyReports} />
            : <LoadingIcon />}
        </div>
        }
        <div className="nearby-reports">
          <ContentHeader
            title={t("list_view.recent_reports_title")}
            icon={<ExclamationBoldIcon color="var(--color-orange-500)" />}
          />
          <div className="list">
            {latestReports
              ? renderList() 
              : <LoadingIcon />}
          </div>
          <div className="list-view-button">
            {latestReports && !dataExhausted &&
              <Button
                title={t("list_view.show_more_button")}
                icon={<ChevronDownBoldIcon />}
                onClick={getMoreReports}
              />
            }
          </div>
          <ReportCta />
        </div>
      </div>
    </FWPage>
  );
}

export default ListView;
