import { SVGProps } from "react";
import "./loading-icon.css";

interface Props extends SVGProps<SVGElement> {
  isFullScreen?: boolean;
}

const SvgComponent = ({ isFullScreen, ...props }: Props) => (
  <div className="loader-container">
    <div className={isFullScreen ? "overlay" : "non-overlay"}>
      <svg
        className="loader"
        width={24}
        height={24}
        viewBox={"0 0 16 16"}
        fill="none"
      >
        <g
          stroke={props?.color ? props.color : "var(--color-dark-500)"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          clipPath="url(#a)"
        >
          <path d="M8 1.333V4" />
          <path strokeOpacity={0.6} d="M8 12v2.667" />
          <path strokeOpacity={0.9} d="m3.287 3.287 1.887 1.886" />
          <path strokeOpacity={0.5} d="m10.827 10.827 1.887 1.886" />
          <path strokeOpacity={0.8} d="M1.333 8H4" />
          <path strokeOpacity={0.4} d="M12 8h2.667" />
          <path strokeOpacity={0.7} d="m3.287 12.713 1.887-1.886" />
          <path strokeOpacity={0.3} d="m10.827 5.173 1.887-1.886" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
);

export { SvgComponent as LoadingIcon };
