import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#1F1F1F"}
      d="m6.16 16.823 9.598-5.541a.227.227 0 0 0 .084-.311L10.88 2.376a1.925 1.925 0 0 0-1.342-.936L4.335.542c-.758-.13-1.5.298-1.766 1.02L.748 6.515a1.923 1.923 0 0 0 .14 1.63L5.85 16.74a.227.227 0 0 0 .31.083ZM4.71 3.23a1.202 1.202 0 1 1 1.202 2.082A1.202 1.202 0 0 1 4.71 3.23Z"
    />
  </svg>
);
export { SvgComponent as TagBoldIcon };
