import "./cta-card.css";
import CtaCard from "./cta-card";
import { MegaphoneBoldIcon } from "../../theme/icons/megaphone-bold-icon";
import Button from "../button";
import ArrowRightBoldIcon from "../../theme/icons/arrow-right-bold-icon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function ReportCta() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CtaCard icon={<MegaphoneBoldIcon />} className="report">
      <div className="text-2xl-black">
        {t("list_view.report_title")}
      </div>
      <div className="text-base">
        {t("list_view.report_description")}
      </div>
      <div className="list-view-button">
        <Button
          title={t("list_view.report_button")}
          icon={<ArrowRightBoldIcon />}
          onClick={() => navigate("/report")}
        />
      </div>
    </CtaCard>
  );
}

export default ReportCta;
