import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    height={20}
    width={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#1F1F1F"}
      fillRule="evenodd"
      d="M14.28 12.21v-1.298l-1.504-1.27V5.874c0-1.679-1.563-3.33-3.154-3.663V1.144c.01-1.493-2.955-1.558-2.965 0v1.068c-1.591.333-3.154 1.984-3.154 3.663v3.766L2 10.911v1.299h12.28Zm-4.326.91a2.011 2.011 0 1 1-3.629 0h3.629Z"
      clipRule="evenodd"
    />
  </svg>
);
export { SvgComponent as BellBoldIcon };
