import { IApiClient } from "../api/api-client";
import { z } from "zod";
import {
  QuestionMarkProduct,
  QuestionMarkProductSchema,
} from "./questionmark-product";

export interface IQuestionMarkRepository {
  findProductByBarcode(code: string): Promise<QuestionMarkProduct>;
  findProducts(name: string): Promise<QuestionMarkProduct[]>;
}

export class QuestionMarkRepository implements IQuestionMarkRepository {
  apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  async findProductByBarcode(code: string) {
    return await this.apiClient.request(
      {
        method: "GET",
        endpoint: `/api/v1.2/products/${code}`,
      },
      QuestionMarkProductSchema
    );
  }

  async findProducts(name: string) {
    return await this.apiClient
      .request(
        {
          method: "GET",
          endpoint: "/api/v1.2/products",
          body: {
            q: name,
            per_page: 5,
            image: true,
          },
        },
        z.object({ products: z.array(QuestionMarkProductSchema) })
      )
      .then((result) => result.products);
  }
}
