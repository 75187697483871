import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentHeader from "../common/content-header";
import { GraphBoldIcon } from "../theme/icons/graph-bold-icon";
import {
  SelectStatisticItem,
  StatisticsType,
} from "../../core/statistics/statistics-model";
import DependencyContext from "../context/dependency-context";
import "./select-statistics-view.css";
import { LoadingIcon } from "../common/loading-icon/loading-icon";
import { FWPage } from "../common/page/page";
import SelectList from "../common/input/select-list";

const SelectStatisticsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { statisticsRepository } = useContext(DependencyContext);

  const [isLoading, setIsLoading] = useState(true);
  const [listType, setListType] = useState("retailChains");
  const [results, setResults] = useState<{
    retailChains: SelectStatisticItem[];
    manufacturers: SelectStatisticItem[];
    products: SelectStatisticItem[];
  }>({
    retailChains: [],
    manufacturers: [],
    products: [],
  });

  const listOptions = Object.entries({
    retailChains: t("statistics.retail_chains_placeholder"),
    manufacturers: t("statistics.manufacturers_placeholder"),
    products: t("statistics.products_placeholder"),
  }).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  useEffect(() => {
    statisticsRepository
      .getSelectStatisticItems(StatisticsType.retailChain)
      .then((result) => {
        setResults((prevState) => {
          return { ...prevState, retailChains: result.items };
        });
      })
      .then(() => setIsLoading(false));
    statisticsRepository
      .getSelectStatisticItems(StatisticsType.manufacturer)
      .then((result) => {
        setResults((prevState) => {
          return { ...prevState, manufacturers: result.items };
        });
      });
    statisticsRepository
      .getSelectStatisticItems(StatisticsType.product)
      .then((result) => {
        setResults((prevState) => {
          return { ...prevState, products: result.items };
        });
      });
  }, []);

  const renderItem = (item: SelectStatisticItem, type: string) => (
    <div
      className="item"
      key={item.id}
      onClick={() => navigate(`/statistics/${type}/${item.id}`)}
    >
      <div className="logo">
        <img src={item.logo.url} alt={`Logo ${item.name}`} />
      </div>
      <div className="name text-base-black">{item.name}</div>
      <div className="reports text-lg-black">{item.amount}</div>
    </div>
  );

  return (
    <FWPage>
      <div className="statistics">
        <div className="header">
          <ContentHeader
            title={t("statistics.title")}
            icon={<GraphBoldIcon color="var(--color-orange-500)" />}
          />

          <div className="form">
            <SelectList
              name={"resultType"}
              onChange={setListType}
              options={listOptions}
              value={listType}
              required
            />
          </div>
        </div>
        <div className="list">
          {isLoading ? (
            <LoadingIcon />
          ) : listType === "retailChains" ? (
            results.retailChains.map((item: SelectStatisticItem) =>
              renderItem(item, "retail-chain")
            )
          ) : listType === "manufacturers" ? (
            results.manufacturers.map((item: SelectStatisticItem) =>
              renderItem(item, "manufacturer")
            )
          ) : listType === "products" ? (
            results.products.map((item: SelectStatisticItem) =>
              renderItem(item, "product")
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </FWPage>
  );
};

export default SelectStatisticsView;
