import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import Toast from "../toast";
import { useLocationPermission } from "../../../../core/utility/use-location-permission";

interface Props {
  onClick: () => void;
}

enum LocationToastStage {
  PROMPT,
  DENIED,
}

const locationStageMapper = (stage: LocationToastStage) =>
  ({
    [LocationToastStage.PROMPT]: t("location_toast.prompt"),
    [LocationToastStage.DENIED]: t("location_toast.denied"),
  }[stage]);

function LocationToast({ onClick }: Props) {
  const [showToast, setShowToast] = useState(true);
  const [stage, setStage] = useState<LocationToastStage>(
    LocationToastStage.PROMPT
  );
  const { locationPermissionState } = useLocationPermission();

  useEffect(() => {
    if (!locationPermissionState) return;
    if (locationPermissionState === "granted") setShowToast(false);
  }, [locationPermissionState]);

  if (!locationPermissionState) return <></>;

  const handleClick = () => {
    switch (stage) {
      case LocationToastStage.PROMPT:
        setStage(LocationToastStage.DENIED);
        onClick();
        break;
      case LocationToastStage.DENIED:
        setShowToast(false);
        break;
    }
  };

  return (
    <>
      {showToast && (
        <Toast
          text={locationStageMapper(stage)}
          hideButton={stage === LocationToastStage.DENIED}
          onClick={handleClick}
          onClose={() => {
            setShowToast(false);
          }}
        />
      )}
    </>
  );
}

export default LocationToast;
