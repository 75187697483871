import { ProductStatistic } from "../../../core/statistics/statistics-model";

import { useTranslation } from "react-i18next";

import "./product-statistics.css";
import Button, { ButtonVariant } from "../../common/button";
import { useEffect, useState } from "react";

interface ProductStatisticsProps {
  items: ProductStatistic[];
}

function ProductStatistics({ items }: ProductStatisticsProps) {
  const { t } = useTranslation();
  const [ visibleItems, setVisibleItems ] = useState<ProductStatistic[]>([]);

  useEffect(() => {
    setVisibleItems(items.slice(0, 5));
  }, [items]);

  const loadMore = () => {
    const n = visibleItems.length;
    setVisibleItems(items.slice(0, n + 5));
  };

  return (
    <div className="row list-row">
      <div className="text-lg-black title">
        {t("statistics.products_title")}
      </div>
      <div className="list">
        {visibleItems.map((item) => (
          <div className="product-item" key={item.id}>
            <div className="name text-base">{item.name}</div>
            <div className="reports text-lg-black">{item.amount}</div>
          </div>
        ))}
      </div>
      {items.length > visibleItems.length && (
        <Button
          title={t("statistics.load_more_produtcs_label")}
          variant={ButtonVariant.tertiary}
          onClick={loadMore}
        />
      )}
    </div>
  );
}

export default ProductStatistics;
