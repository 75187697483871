import "./input.css";
import "./autocomplete.css";
import { KeyboardEventHandler, ReactElement, useEffect, useRef, useState } from "react";

// .productName-container > div {
//   display: contents !important;
// }

// .productName-container input {
//   width: 100%;
//   padding: 12px 16px;
//   border: none;
//   box-sizing: border-box;
// }

// .productName-container .autocomplete-option {
//   width: 100%;
//   padding: 12px 16px;
//   box-sizing: border-box;
// }

// renderItem={(item, isHighlighted) =>
//   <div key={item.barcode} className="autocomplete-option" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
//     {item.label}
//   </div>
// }

interface AutocompleteProps<T> {
    className?: string,
    name: string,
    placeholder?: string,
    items: T[];
    getItemValue: (item: T) => string;
    getItemLabel?: (item: T) => string | ReactElement;
    getItemImage?: (item: T) => string;
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onSelect: (val: T) => void;
    disabled?: boolean;
    footer?: string | ReactElement | null;
}

const Autocomplete = <T,>({
  className = "",
  name,
  placeholder = "",
  items,
  getItemValue,
  getItemLabel=getItemValue,
  getItemImage,
  value,
  onChange,
  onSelect,
  disabled=false,
  footer=null,
}: AutocompleteProps<T>) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [focusIndex, setFocusIndex] = useState<number>(-1);

  useEffect(() => {
    setFocusIndex(-1);
  }, [items]);

  const onFocus = () => {
    setHasFocus(true);
  };

  const onBlur = () => {
    setTimeout(() => {
      setHasFocus(false);
      setFocusIndex(-1);
    }, 500);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if(event.code === "Enter" 
      || event.code === "Tab") {
      handleSelect(items[focusIndex]);
    } else if(!hasFocus) {
      setHasFocus(true);
    } else if(event.code === "ArrowDown") {
      setFocusIndex(i => Math.min(i+1, items.length-1));
    } else if(event.code === "ArrowUp") {
      setFocusIndex(i => Math.max(i-1, 0));
    }
  };

  const handleSelect = (item: T) => {
    if(!hasFocus) return;

    setHasFocus(false);

    if(item) {
      onSelect(item);
    }
  };

  return (
    <div className={`input-wrapper ${className}`}>
      <div className="input-container">
        <input 
          className="input"
          placeholder={placeholder}
          aria-label={name}
          data-testid={name}
          tabIndex={0}
          name={name}
          onChange={onChange}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <div className={`suggestions ${hasFocus || 'hidden'}`}>
          {items.map((item, i) => {
            const focused = i === focusIndex;
            return <div 
              key={getItemValue(item)} 
              className={`${focused && 'focused'} suggestion`}
              onClick={() => handleSelect(item)}>
                {getItemImage && <img src={getItemImage(item)}/>}
                <div className="label">
                  {getItemLabel(item)}
                </div>
            </div>;
          })}
          {footer && 
            <div className="footer">{footer}</div>
          }
        </div>
      </div>
    </div>
  );
};

export default Autocomplete;
