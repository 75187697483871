import * as React from "react"
import { SVGProps } from "react"
const ScanIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#16282B"
      d="M16 .923V4a.923.923 0 0 1-1.846 0V1.846H12A.923.923 0 0 1 12 0h3.077A.923.923 0 0 1 16 .923ZM4 11.693H1.846V9.537a.923.923 0 0 0-1.846 0v3.077a.923.923 0 0 0 .923.924H4a.923.923 0 0 0 0-1.847Zm11.077-3.078a.923.923 0 0 0-.923.923v2.154H12a.923.923 0 0 0 0 1.847h3.077a.923.923 0 0 0 .923-.924V9.538a.923.923 0 0 0-.923-.923ZM.923 4.923A.923.923 0 0 0 1.846 4V1.846H4A.923.923 0 1 0 4 0H.923A.923.923 0 0 0 0 .923V4a.923.923 0 0 0 .923.923Zm4-1.846A.923.923 0 0 0 4 4v5.538a.923.923 0 1 0 1.846 0V4a.923.923 0 0 0-.923-.923ZM12 9.538V4a.923.923 0 0 0-1.846 0v5.538a.923.923 0 0 0 1.846 0ZM8 3.077A.923.923 0 0 0 7.077 4v5.538a.923.923 0 1 0 1.846 0V4A.923.923 0 0 0 8 3.077Z"
    />
  </svg>
)
export default ScanIcon
