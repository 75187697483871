import * as React from "react";
import "./input.css";
import ChevronDownBoldIcon from "../../theme/icons/chevron-down-bold-icon";

interface SelectOptionPair<T> {
  label: string;
  value: T;
}

type SelectOption<T> = string | SelectOptionPair<T>;

interface SelectProps<T> {
  className?: string;
  name: string;
  onChange: (value: T) => void;
  required?: boolean;
  placeholder?: string;
  options: SelectOption<T>[];
  value: T;
}

const SelectList = <T,>({
  className = "",
  name,
  onChange,
  options,
  value,
  required,
  placeholder,
}: SelectProps<T>) => {
  const stringValue =
    options.map((o) => o as SelectOptionPair<T>).find((o) => o.value === value)
      ?.label || (value as string);

  const props = {
    className: `${value === "" && "empty"} input`,
    "aria-label": name,
    "data-testid": name,
    tabIndex: 0,
    name: name,
    value: stringValue,
  };

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    if (required && event.target.value === "") return;

    let value =
      options
        .map((o) => o as SelectOptionPair<T>)
        .find((o) => o.label === event.target.value)?.value ||
      (event.target.value as T);

    onChange(value);
  };

  return (
    <div className={`${className} input-wrapper`}>
      <div className="input-container">
        <select {...props} onChange={handleChange}>
          {(!required || placeholder) && (
            <option
              className="placeholder"
              value=""
              {...{ disabled: required }}
            >
              {placeholder}
            </option>
          )}
          {options.map((option) => {
            const label =
              (option as SelectOptionPair<T>).label || (option as string);

            return (
              <option key={label} value={label}>
                {label}
              </option>
            );
          })}
        </select>
        <ChevronDownBoldIcon />
      </div>
    </div>
  );
};

export default SelectList;
