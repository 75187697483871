import * as React from "react"
import { SVGProps } from "react"
const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="search-icon"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#1F1F1F"
      d="M5 10.004a6.044 6.044 0 0 0 6.037 6.037c1.063 0 2.06-.277 2.929-.762L17.686 19 20 16.687l-3.712-3.712a6 6 0 0 0 .785-2.971 6.044 6.044 0 0 0-6.037-6.038A6.043 6.043 0 0 0 5 10.004Zm9.085 0a3.051 3.051 0 0 1-3.048 3.048 3.05 3.05 0 0 1-3.048-3.048 3.05 3.05 0 0 1 3.048-3.049 3.05 3.05 0 0 1 3.048 3.049Z"
    />
  </svg>
)
export default SearchIcon
