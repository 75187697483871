import React from "react";
import { useNavigate } from "react-router-dom";
import { PinBoldIcon } from "../theme/icons/pin-bold-icon";
import { TagBoldIcon } from "../theme/icons/tag-bold-icon";
import "./card.css";
import { ApiReport } from "../../core/report/report-model";
import moment from "moment";

interface ReportCardProps {
  report: ApiReport;
}

function ReportCard({
  report
}: ReportCardProps) {
  const navigate = useNavigate();

  return (
    <div className="card" onClick={() => navigate(`/report/${report.id}`)}>
      <img className="card-image" alt={`${report.product.name}`} src={report.images[0]?.url || report.product.images[0]?.url} />
      <div className="card-text">
        <div className="card-title text-lg-black">{report.product.name}</div>
        <div className="card-info-item text-xs">
          <TagBoldIcon
            className="card-info-item-icon"
            color={"var(--color-orange-500)"}
          />
          {report.product.manufacturer?.name}
        </div>
        <div className="card-info">
          <div className="card-info-item text-xs">
            <PinBoldIcon
              className="card-info-item-icon"
              color={"var(--color-orange-500)"}
            />
            {report.pointOfSale.retailChain?.name || report.pointOfSale.name}
          </div>
          <div className="card-info-item text-xs">
            <PinBoldIcon
              className="card-info-item-icon"
              color={"var(--color-orange-500)"}
            />
            {moment(report.creationTime).format("DD-MM-YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportCard;
