import React, { FC } from "react";
import DependencyContext, { IDependencyContainer } from "./dependency-context";

interface Props extends IDependencyContainer {
  children: React.ReactNode;
}

const DependencyProvider: FC<Props> = ({children, ...dependencies}) => {

  return (
    <DependencyContext.Provider value={dependencies}>
      {children}
    </DependencyContext.Provider>
  );
};

export default DependencyProvider;
