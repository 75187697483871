import { z } from "zod";
import { createApiResponseSchema } from "./api-response-model";
import { buildFormData } from "../utility/build-form-data";
import { ApiError, ApiErrorSchema } from "./api-error-model";

type Schema = z.ZodTypeAny;

export class ApiNetworkError extends Error {
  constructor(method: string, url: string, message: string = "Network error") {
    super(
      JSON.stringify({
        method,
        url,
        message,
      })
    );
    Object.setPrototypeOf(this, ApiNetworkError.prototype);
  }
}

export class ApiServerError extends Error {
  constructor(method: string, url: string, status: number, response: string) {
    super(
      JSON.stringify({
        status,
        method,
        url,
        response,
      })
    );
    Object.setPrototypeOf(this, ApiServerError.prototype);
  }
}

export class ApiClientError extends Error {
  apiError: ApiError | undefined;

  constructor(method: string, url: string, status: number, response: string) {
    super(
      JSON.stringify({
        status,
        method,
        url,
        response,
      })
    );

    try {
      const json = JSON.parse(response);
      const apiError = ApiErrorSchema.parse(json);
      this.apiError = apiError;
    } catch (e) {}

    Object.setPrototypeOf(this, ApiClientError.prototype);
  }
}

export interface RequestOptions {
  method: "POST" | "GET";
  endpoint: string;
  headers?: HeadersInit;
  body?: z.output<Schema>;
}

export interface IApiClient {
  request<RS extends Schema>(
    options: RequestOptions,
    responseSchema?: RS
  ): Promise<z.output<RS>>;
  requestItems<RS extends Schema>(
    options: RequestOptions,
    responseSchema: RS,
    getAll?: boolean
  ): Promise<z.output<RS>[]>;
}

export class ApiClient implements IApiClient {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async request<RS extends Schema>(
    options: RequestOptions,
    responseSchema?: RS
  ): Promise<z.output<RS>>;
  async request<RS extends Schema>(
    options: RequestOptions,
    responseSchema?: RS
  ): Promise<z.output<RS> | void> {
    var url = new URL(options.endpoint, this.baseUrl).href;

    const requestOptions: RequestInit = {
      method: options.method,
      headers: options.headers ?? {},
    };

    if (options.body && options.method === "GET") {
      const queryString = new URLSearchParams(options.body).toString();
      url += `?${queryString}`;

      // TODO: Remove this, only way to filter out undefined brands + retailer products from question mark.
      // Their api wants you to send it like this retailer_ids[]: 1 \n retailer_ids[]: 2
      if (options.endpoint === "/api/v1.2/products") {
        let mappedUrl = Array(100)
          .fill(1)
          .map((n, i) => n + i)
          .map((i) => `&retailer_id[]=${i}`)
          .join("");
        url += mappedUrl;
      }
    } else if (options.body) {
      const formData = new FormData();
      buildFormData(formData, options.body);
      requestOptions.body = formData;
    }

    const response = await fetch(url, requestOptions).catch((e) => {
      throw new ApiNetworkError(options.method, url, e.message);
    });

    if (response.status >= 400 && response.status < 500) {
      throw new ApiClientError(
        options.method,
        url,
        response.status,
        await response.text()
      );
    }

    if (response.status >= 500 && response.status < 600) {
      throw new ApiServerError(
        options.method,
        url,
        response.status,
        await response.text()
      );
    }

    if (responseSchema) {
      return await response.json().then((json) => responseSchema.parse(json));
    }
  }

  async requestItems<RS extends Schema>(
    options: RequestOptions,
    responseSchema: RS,
    getAll: boolean = false
  ): Promise<z.output<RS>> {
    const apiRS = createApiResponseSchema(responseSchema);

    return new Promise(async (resolve) => {
      var items: z.output<RS>[] = [];
      var done = !getAll;

      do {
        const response = await this.request(
          {
            ...options,
            body: {
              ...options.body,
              skipCount: options.body?.skipCount || 0 + items.length,
            },
          },
          apiRS
        );

        items.push(...response.items);
        done =
          done ||
          response.items.length === 0 ||
          items.length === response.totalCount;
      } while (!done);

      resolve(items);
    });
  }
}
