import { XBoldIcon } from "../../theme/icons/x-bold-icon";
import Button from "../button";
import "./lightbox.css";

interface LightBoxProps {
  image: string | null;
  onClose: () => void;
}

function LightBox({ image, onClose }: LightBoxProps) {
  if(!image) return <></>;

  return (
  <div className="lightbox" onClick={onClose}>
    <img 
      src={image}
      onClick={e => e.preventDefault()}
    />
    <Button
      title={""}
      icon={<XBoldIcon width={24} height={24} />}
      onClick={() => {}}
    />
  </div>
  );
}

export default LightBox;