import * as React from "react";
import "./checkbox.css";

interface InputProps {
  className?: string;
  name: string,
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox = ({
  className = "",
  name,
  label,
  checked = false,
  onChange = () => {},
  disabled = false,
}: InputProps) => {
  return (
    <label className={`checkbox-wrapper text-base ${className} ${checked ? "active" : ""}`}>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <div className="box">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.44211 19.7902L0 12.3481L3.89611 8.45201L7.46087 12.018L18.1039 1.375L22 5.27111L7.44211 19.7902Z" fill="#1F1F1F"/>
        </svg>
      </div>
      {label}
    </label>
  );
};

export default CheckBox;
