import { useZxing, Result } from "react-zxing";
import "./scanner.css";
import Button from "../button";
import { useMlTranslation } from "../../../core/localise/i18n";
import { useEffect, useRef, useState } from "react";

interface ScannerProps {
  dismiss: () => void,
  onScan: (code: string) => void,
}

const constraints: MediaStreamConstraints = {
  video: true,
  audio: false,
};

const Scanner = ({
  dismiss,
  onScan
}: ScannerProps) => {
  const { t, mlt } = useMlTranslation();
  const [ hasScanned, setHasScanned ] = useState(false);
  const hasError = useRef(false);
  const { ref } = useZxing({
    constraints: {
      video: {
        facingMode: { ideal: "environment" },
        ...{focusMode: { ideal: "continuous" }}
      },
    },
    onDecodeResult: (result: Result) => {
      onScan(result.getText());
      setHasScanned(true);
    },
    onError: () => {
      if(!hasError.current) {
        hasError.current = true;
        alert(t('add_report.scanner.error_camera'));
        dismiss();
      }
    },
    onDecodeError: () => {
    },
  });

  return (
    <div className={`scanner ${hasScanned && "done"}`}>
        <video ref={ref} />
        <div className="overlay">
          <div className="top">
            <h2>{t("add_report.scanner.title")}</h2>
            <p>{mlt("add_report.scanner.instruction")}</p>
          </div>
          <div className="center">
            <div className="frame">

            </div>
          </div>
          <div className="bottom">
            <div className="button-container">
              <Button
                title={t("add_report.scanner.back")}
                onClick={dismiss}
              />
            </div>
          </div>
        </div>
    </div>
  );
};

export default Scanner;
