import "./cta-card.css";
import CtaCard from "./cta-card";
import Button from "../button";
import ArrowRightBoldIcon from "../../theme/icons/arrow-right-bold-icon";
import { ShareBoldIcon } from "../../theme/icons/share-bold-icon";
import { useMlTranslation } from "../../../core/localise/i18n";

function ShareCta() {
  const { t, mlt } = useMlTranslation();

  const share = () => {
    const shareData: ShareData = {
      url: window.location.origin,
    };
    if (navigator.share && navigator.canShare(shareData)) {
      navigator.share(shareData).catch((e) => console.log(e));
    }
  };

  return (
    <CtaCard icon={<ShareBoldIcon />} className="share">
      <div className="text-2xl-black">{mlt("report.share_title")}</div>
      <div className="text-base">{mlt("report.share_description")}</div>
      <div className="list-view-button">
        <Button
          title={t("report.share_button")}
          icon={<ArrowRightBoldIcon />}
          onClick={share}
        />
      </div>
    </CtaCard>
  );
}

export default ShareCta;
