import { useLocation, useNavigate } from "react-router-dom";
import ListViewIcon from "../../map/assets/list-view-icon";
import MapViewIcon from "../../map/assets/map-view-icon";

import "./search-bar.css";
import SearchIcon from "./assets/search-icon";
import { debounce } from "../../../core/utility/debounce";
import { useContext, useEffect, useRef, useState } from "react";
import Autocomplete from "../input/autocomplete";
import DependencyContext from "../../context/dependency-context";
import { ApiSearchResult, ApiSearchResultType } from "../../../core/search/search-model";
import { ReportFilter } from "../../../core/report/report-repository";
import { useTranslation } from "react-i18next";
import { XBoldIcon } from "../../theme/icons/x-bold-icon";

const defaultLocation = { lat: 52.11, lng: 5.17 };

interface SearchBarProps {
    onFilter: (filter: ReportFilter) => void;
}

interface SearchSuggestion {
    id: string,
    image: string,
    name: string,
    description: string,
    filter: ReportFilter,
};

const mapTypeToFilterKey = (type: ApiSearchResultType): (keyof ReportFilter) => {
    switch(type) {
        case ApiSearchResultType.Manufacturer:
            return 'manufacturerId';
        case ApiSearchResultType.RetailChain:
            return 'retailChainId';
        case ApiSearchResultType.PointOfSale:
            return 'pointOfSaleId';
        case ApiSearchResultType.Product:
            return 'productId';
    }
};

export const SearchBar = ({ onFilter }: SearchBarProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    
    const [userLocation, setUserLocation] = useState(defaultLocation);
    const [placesFooter, setPlacesFooter] = useState<HTMLDivElement | null>(null);
    const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null);

    const {searchRepository} = useContext(DependencyContext);
    const [suggestions, setSuggestions] = useState<(SearchSuggestion)[]>([]);
    const [query, setQuery] = useState('');

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({ coords }) => {
                setUserLocation({
                    lat: coords.latitude,
                    lng: coords.longitude,
                });
            });
        }
    }, []);

    useEffect(() => {
      if(placesFooter) {
        placesServiceRef.current = new google.maps.places.PlacesService(placesFooter);
      }
    }, [placesFooter]);
    
    const processQuery = async () => {
        if(/^ *$/.test(query)) {
            setSuggestions([]);
        } else {
            const results = await searchRepository.getSearchResults(query)
                .then((results) => results.slice(0, 5));
            const places: google.maps.places.PlaceResult[] = await new Promise(resolve => {
                placesServiceRef.current?.textSearch({
                    location: userLocation,
                    query: query,
                }, (results) => {
                    resolve((results || [])
                        .filter(place => place.types?.some(type => ['locality', 'postal_code'].includes(type)))
                        .slice(0, 1));
                });
            });
        
            setSuggestions([
                ...places.map(place => ({
                    id: place.place_id!,
                    name: place.name!,
                    image: place.icon || '',
                    description: place.vicinity!,
                    filter: {
                        maxDistance: 20,
                        latitude: place.geometry?.location?.lat() || defaultLocation.lat,
                        longitude: place.geometry?.location?.lng() || defaultLocation.lng,
                    },
                })),
                ...results.map(result => ({
                    id: result.id,
                    name: result.name,
                    image: result.logo.url,
                    description: t(`search.${result.type.toLowerCase()}`),
                    filter: {
                        [mapTypeToFilterKey(result.type)]: result.id,
                    }
                }))
            ]);
        }
    };

    const handleSelect = (item: SearchSuggestion) => {
        var filter: ReportFilter = item.filter;
        setQuery(item.name);
        onFilter(filter);
    };

    const handleClear = () => {
        setQuery('');
        onFilter({});
    };

    useEffect(() => {
        debounce('PROCESS_SEARCH_QUERY', processQuery, 250);
    }, [query]);

    return (
        <div className="search-bar">
            <SearchIcon/>
            <Autocomplete
                name="search"
                placeholder={t("search.placeholder")}
                items={suggestions}
                getItemValue={item => item.id}
                getItemLabel={item => <>
                    <span className="text-base-black">{item.name}</span>
                    <span>{item.description}</span>
                </>}
                getItemImage={item => item.image}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onSelect={handleSelect}
                footer={<div ref={ref => setPlacesFooter(ref)}></div>}
            />
            {query &&
                <div 
                    className="clear-button" 
                    onClick={handleClear}
                >
                    <XBoldIcon />
                </div>
            }
            { location.pathname === '/' &&
                <div className="switch-view-button" onClick={() => navigate('/list')}>
                    <ListViewIcon/>
                </div>
            }
            { location.pathname !== '/' &&
                <div className="switch-view-button" onClick={() => navigate('/')}>
                    <MapViewIcon/>
                </div>
            }
        </div>
    );
}