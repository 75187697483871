import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g fill={props.color ? props.color : "#1F1F1F"}>
      <path d="M16 1H0v3.16h16V1ZM16 11.292H0v3.16h16v-3.16ZM16 6.147H0v3.159h16v-3.16Z" />
    </g>
  </svg>
);
export { SvgComponent as HamburgerBoldIcon };
