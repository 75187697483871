import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#1F1F1F"}
      d="M12.8 9.6c-.808 0-1.544.312-2.112.816L6.4 8.24v-.32l3.976-2.648A3.166 3.166 0 0 0 12.8 6.4a3.2 3.2 0 1 0 0-6.4c-1.768 0-3.112 1.36-3.184 3.064L5.504 5.8a3.183 3.183 0 0 0-2.304-1 3.2 3.2 0 1 0 0 6.4c.872 0 1.656-.352 2.232-.92l4.208 2.136c-.016.128-.04.256-.04.384a3.2 3.2 0 1 0 6.4 0 3.2 3.2 0 0 0-3.2-3.2Z"
    />
  </svg>
);
export { SvgComponent as ShareBoldIcon };
