import * as React from "react"
import { SVGProps } from "react"
const Ketchup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    {...props}
  >
    <g>
      <path
        fill="#353535"
        d="M34.036 67.959V31.604a.92.92 0 0 1 .92-.92h13.507a.92.92 0 0 1 .92.92v36.355a.92.92 0 0 1-.92.92H34.957a.92.92 0 0 1-.92-.92Z"
      />
      <path
        fill="#FFAA6E"
        d="M36.156 30.684V28.84c0-.595.482-1.077 1.077-1.077h8.955c.595 0 1.077.482 1.077 1.077v1.844h-11.11Z"
      />
      <path
        fill="#FFAA6E"
        d="M44.86 27.763h-6.3l2.6-11.53a.564.564 0 0 1 1.1 0l2.6 11.53Z"
      />
      <path
        fill="#000"
        d="M49.384 31.604V67.96a.92.92 0 0 1-.92.92h-2.12c.508 0 .921-.411.921-.92V31.604a.921.921 0 0 0-.92-.92h2.119a.92.92 0 0 1 .92.92Z"
      />
      <path
        fill="#FFC76E"
        d="M41.71 15.795v11.968h-3.15l2.6-11.53a.564.564 0 0 1 .55-.438ZM60.798 68.01v-4.79c0-.29.236-.526.526-.526h9.715c.29 0 .525.235.525.526v4.79c0 .29-.235.526-.525.526h-9.716a.525.525 0 0 1-.525-.525Z"
      />
      <path
        fill="#353535"
        d="m78.174 30.184-.295 4.928a7.719 7.719 0 0 1-.867 3.151 16.879 16.879 0 0 0-1.957 7.913c0 2.13.393 4.17 1.111 6.047a7.765 7.765 0 0 1 .485 3.257l-.316 5.044a2.286 2.286 0 0 1-2.277 2.142l-15.74.025a2.282 2.282 0 0 1-2.284-2.146l-.308-5.068c-.067-1.1.089-2.205.482-3.235a16.889 16.889 0 0 0 1.106-6.032c0-2.858-.708-5.552-1.959-7.916a7.713 7.713 0 0 1-.866-3.144l-.302-4.963a2.285 2.285 0 0 1 2.282-2.423h19.427a2.281 2.281 0 0 1 2.278 2.42Z"
      />
      <path
        fill="#000"
        d="m78.174 30.184-.295 4.928a7.718 7.718 0 0 1-.867 3.151 16.876 16.876 0 0 0-1.958 7.912c0 2.13.394 4.17 1.113 6.047a7.754 7.754 0 0 1 .484 3.257l-.315 5.044a2.287 2.287 0 0 1-2.278 2.142l-15.74.025c-.056 0-.111-.001-.166-.006l11.945-.019a2.286 2.286 0 0 0 2.277-2.142l.316-5.044a7.77 7.77 0 0 0-.485-3.257 16.89 16.89 0 0 1-1.111-6.047c0-2.857.707-5.55 1.956-7.912a7.718 7.718 0 0 0 .867-3.15l.296-4.929a2.284 2.284 0 0 0-2.28-2.42h3.962a2.282 2.282 0 0 1 2.28 2.42Z"
      />
      <path
        fill="#FFDFC1"
        d="M70.811 55.528h-9.26c-.967 0-1.771-.74-1.852-1.703l-1.547-18.584a1.858 1.858 0 0 1 1.85-2.012H72.36c1.087 0 1.94.929 1.851 2.012l-1.548 18.584a1.858 1.858 0 0 1-1.852 1.703Z"
      />
      <path
        fill="#D45F61"
        d="M66.181 48.837a3.261 3.261 0 1 0 0-6.523 3.261 3.261 0 0 0 0 6.523Z"
      />
      <path
        fill="#88D45F"
        d="M66.39 42.313a2.394 2.394 0 0 1-2.394-2.394 2.394 2.394 0 0 1 2.393 2.394Z"
      />
      <path
        fill="#88D45F"
        d="M66.39 42.313c0-.847.686-1.534 1.534-1.534 0 .848-.687 1.534-1.535 1.534Z"
      />
      <path
        fill="#FFAA6E"
        d="M66.39 68.536v-5.84h4.81c.2 0 .364.162.364.364v5.113a.364.364 0 0 1-.364.364h-4.81Z"
      />
      <path
        fill="#353535"
        d="M25.587 68.84H5.131a3.96 3.96 0 0 1-3.952-4.196l1.43-23.95a3.96 3.96 0 0 1 2.536-3.462l1.918-.734a4.044 4.044 0 0 0 2.592-3.566l.837-16.09h9.735l.837 16.09a4.043 4.043 0 0 0 2.593 3.566l1.917.734a3.96 3.96 0 0 1 2.537 3.462l1.43 23.95a3.96 3.96 0 0 1-3.954 4.196Z"
      />
      <path
        fill="#000"
        d="M25.587 68.84H21.28a3.96 3.96 0 0 0 3.953-4.196l-1.43-23.95a3.96 3.96 0 0 0-2.537-3.462l-1.918-.734a4.045 4.045 0 0 1-2.592-3.566l-.836-16.09h4.307l.837 16.09a4.044 4.044 0 0 0 2.593 3.566l1.916.734a3.959 3.959 0 0 1 2.537 3.462l1.43 23.95a3.96 3.96 0 0 1-3.953 4.196Z"
      />
      <path
        fill="#FFAA6E"
        d="M9.977 16.317v-4.792c0-.29.236-.525.526-.525h9.716c.29 0 .526.235.526.525v4.792c0 .29-.236.526-.526.526h-9.716a.525.525 0 0 1-.526-.526Z"
      />
      <path
        fill="#FFDFC1"
        d="M24.069 63.445H7.067a1.802 1.802 0 0 1-1.802-1.802v-17c0-.996.807-1.803 1.802-1.803h17c.995 0 1.802.807 1.802 1.802v17.001c0 .995-.807 1.802-1.802 1.802Z"
      />
      <path
        fill="#D45F61"
        d="M15.568 57.563a3.261 3.261 0 1 0 0-6.522 3.261 3.261 0 0 0 0 6.522Z"
      />
      <path
        fill="#88D45F"
        d="M15.777 51.04a2.394 2.394 0 0 1-2.394-2.393 2.394 2.394 0 0 1 2.394 2.394ZM15.777 51.04c0-.847.687-1.534 1.534-1.534 0 .847-.687 1.534-1.534 1.534Z"
      />
      <path
        fill="#FFAA6E"
        d="M9.977 18.138v-.39c0-.174.142-.314.316-.314h10.136c.174 0 .315.141.315.314v.39a.315.315 0 0 1-.315.315H10.293a.315.315 0 0 1-.316-.315Z"
      />
      <path
        fill="#FFC76E"
        d="M9.977 16.471v-5.1c0-.204.167-.37.371-.37h5.011v5.841h-5.01a.371.371 0 0 1-.372-.37Z"
      />
    </g>
    <path
      fill="#F7A600"
      d="M40 80C17.944 80 0 62.056 0 40S17.944 0 40 0s40 17.944 40 40-17.944 40-40 40Zm0-74.76C20.833 5.24 5.24 20.833 5.24 40c0 19.167 15.593 34.76 34.76 34.76 19.166 0 34.76-15.593 34.76-34.76C74.76 20.833 59.166 5.24 40 5.24Z"
    />
    <path
      fill="#F7A600"
      d="M64.579 11.716 11.716 64.579l3.705 3.706L68.284 15.42l-3.705-3.705Z"
    />
  </svg>
)
export default Ketchup
