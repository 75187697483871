import * as React from "react"
import { SVGProps } from "react"
const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#F5A400"
      fillRule="evenodd"
      d="M4.405 2.352V0H2.613v2.352h1.792ZM1.064 13.888A1.075 1.075 0 0 1 0 12.828V5.787h14v7.04c0 .58-.452 1.061-1.027 1.061H1.064ZM0 4.891h14V2.714c0-.575-.452-1.034-1.027-1.034h-.653v1.131c0 .247-.21.437-.456.437H9.191c-.246 0-.459-.19-.459-.437V1.68H5.298v1.131c0 .247-.21.437-.46.437H2.165a.446.446 0 0 1-.455-.437V1.68h-.646C.489 1.68 0 2.135 0 2.714v2.177Zm11.424-2.539V0H9.632v2.352h1.792Z"
      clipRule="evenodd"
    />
  </svg>
)
export default CalendarIcon
