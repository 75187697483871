import React from "react";
import "./button.css";

export enum ButtonVariant {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
}

interface ButtonProps {
  className?: string;
  textClassName?: string;
  title: string;
  icon?: React.ReactElement;
  variant?: ButtonVariant;
  onClick: () => void;
}

function Button({
  title,
  icon,
  variant = ButtonVariant.primary,
  onClick,
  className = "",
  textClassName = "",
}: ButtonProps) {
  return (
    <button
      className={`btn btn-${variant} ${className}`}
      type={"button"}
      onClick={onClick}
    >
      <div className={`text-base-black ${textClassName}`}>{title}</div>
      {icon != null ? <div>{icon}</div> : null}
    </button>
  );
}

export default Button;
