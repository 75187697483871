import { DeceptionStatistic } from "../../../core/statistics/statistics-model";
import { AsteriskBoldIcon } from "../../theme/icons/asterisk-bold-icon";
import BarGraph from "./bar-graph";
import { useTranslation } from "react-i18next";

import "./report-types.css";

interface ReportTypesProps {
  items: DeceptionStatistic[];
}

function ReportTypes({ items }: ReportTypesProps) {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="text-lg-black title">
        {t("statistics.type_title")}
      </div>
      <div className="graph">
        <BarGraph
          items={items.map((item) => {
            return {
              label: t(
                `statistics.types.${item.deceptionType.toLowerCase()}`
              ),
              amount: item.amount,
            };
          })}
        />
      </div>
      <div className="text-sm disclaimer">
        <AsteriskBoldIcon
          className="disclaimer-icon"
          color={"var(--color-light-100)"}
        />
        {t("statistics.type_disclaimer")}
      </div>
    </div>
  );
}

export default ReportTypes;
