import { IApiClient } from "../api/api-client";
import { ApiResponseStatisticsItems, ApiResponseStatisticsItemsSchema, ApiStatistics, ApiStatisticsSchema, StatisticsType } from "./statistics-model";

export interface IStatisticsRepository {
    getSelectStatisticItems(type: StatisticsType): Promise<ApiResponseStatisticsItems>;
    getStatistics(id: string, type: StatisticsType):Promise<ApiStatistics>;
}

export class StatisticsRepository implements IStatisticsRepository {
    apiClient: IApiClient;

    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    async getSelectStatisticItems(type: StatisticsType): Promise<ApiResponseStatisticsItems> {
        return await this.apiClient.request({
            method: "GET",
            endpoint: `/api/statistics/${type}`,
        }, ApiResponseStatisticsItemsSchema); 
    };

    async getStatistics(id: string, type: StatisticsType): Promise<ApiStatistics> {
        return await this.apiClient.request({
            method: "GET",
            endpoint: `/api/statistics/${type}/${id}`,
        }, ApiStatisticsSchema); 
    }
};
