import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ArrowRightBoldIcon from "../theme/icons/arrow-right-bold-icon";
import { MegaphoneBoldIcon } from "../theme/icons/megaphone-bold-icon";
import Ketchup from "../theme/illustrations/ketchup";
import Button, { ButtonVariant } from "../common/button";
import Input from "../common/input/input";
import "./add-report-view.css";
import "../common/input/autocomplete.css";
import ContentHeader from "../common/content-header";
import CheckBox from "../common/checkbox";
import { compressImage } from "../../core/utility/compress-image";
import {
  DraftReport,
  DraftReportProperties,
  DraftReportSchema,
} from "../../core/draft-report/draft-report-model";
import { debounce } from "../../core/utility/debounce";
import DependencyContext from "../context/dependency-context";
import { Link, useNavigate } from "react-router-dom";
import { useMlTranslation } from "../../core/localise/i18n";
import { FWPage } from "../common/page/page";
import Scanner from "../common/scanner/scanner";
import {
  ApiQuestionMarkProduct,
  QuestionMarkProduct,
} from "../../core/questionmark/questionmark-product";
import SelectList from "../common/input/select-list";
import Select from "react-select";
import { XBoldIcon } from "../theme/icons/x-bold-icon";
import ScanIcon from "./assets/scan-icon";
import { LoadingIcon } from "../common/loading-icon/loading-icon";

const defaultLocation = { lat: 52.11, lng: 5.17 };

type IFormData = DraftReport & { barcode?: string };

const deceptionTypes = Object.entries({
  Deception: "Lokingredient",
  HealthClaim: "Gezondheidsclaim",
  ChildMarketing: "Kindermarketing",
  Packaging: "Verpakking",
  Ingredients: "Ingrediënten",
}).map(([key, value]) => ({
  label: value,
  value: key,
}));

function AddReportView() {
  const { t } = useTranslation();
  const { mlt } = useMlTranslation();
  const navigate = useNavigate();
  const { qmRepository, draftReportRepository } = useContext(DependencyContext);

  const [selectedQuestionMark, setSelectedQuestionMark] =
    useState<QuestionMarkProduct | null>(null);
  const [selectedPlaceResult, setSelectedPlaceResult] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [userLocation, setUserLocation] = useState(defaultLocation);
  const [placesFooter, setPlacesFooter] = useState<HTMLDivElement | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
    null
  );
  const [pointOfSaleSuggestions, setPointOfSaleSuggestions] = useState<
    google.maps.places.PlaceResult[]
  >([]);

  const [scannerShown, setScannerShown] = useState(false);
  const [productLocked, setProductLocked] = useState(false);
  const [productSuggestions, setProductSuggestions] = useState<
    QuestionMarkProduct[]
  >([]);

  const [imgUris, setImgUris] = useState<string[]>([]);

  const retailerFallbackOffset = 1000000;
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState<IFormData>({
    productName: "",
    product: null,
    manufacturer: "",
    pointOfSale: "",
    deception: deceptionTypes[0].value,
    description: "",
    images: [],
    price: null,
    name: "",
    email: "",
    newsletter: false,
    googlePlace: null,
  });

  const [fieldErrors, setFieldErrors] = useState<
    Partial<Record<keyof typeof DraftReportProperties, string>>
  >({});

  const setFormProps = (props: Partial<IFormData>) => {
    setFormData((formdata) => Object.assign({}, formdata, props));
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setUserLocation({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (placesFooter) {
      placesServiceRef.current = new google.maps.places.PlacesService(
        placesFooter
      );
    }
  }, [placesFooter]);

  useEffect(() => {
    if (/^ *$/.test(formData.productName)) {
      setProductSuggestions([]);
    } else {
      debounce(
        "ProductAutocomplete",
        () => {
          qmRepository
            .findProducts(formData.productName)
            .then((products) => {
              setProductSuggestions(products.slice(0, 5));
            })
            .catch(() => {
              setProductSuggestions([]);
            });
        },
        250
      );
    }
  }, [formData.productName, qmRepository]);

  useEffect(() => {
    if (selectedQuestionMark) {
      onProductAutocomplete(selectedQuestionMark);
    }
  }, [selectedQuestionMark]);

  useEffect(() => {
    if (selectedPlaceResult) {
      onPointOfSaleAutocomplete(selectedPlaceResult);
    }
  }, [selectedPlaceResult]);

  useEffect(() => {
    if (/^ *$/.test(formData.pointOfSale)) {
      setPointOfSaleSuggestions([]);
    } else {
      debounce(
        "PlacesAutocomplete",
        () => {
          const callback = (
            results: google.maps.places.PlaceResult[] | null
          ) => {
            setPointOfSaleSuggestions(
              (results || [])
                .filter((place) =>
                  place.types?.some((type) => ["supermarket"].includes(type))
                )
                .slice(0, 5)
            );
          };

          if (
            userLocation === defaultLocation ||
            pointOfSaleSuggestions.length === 0
          ) {
            placesServiceRef.current?.textSearch(
              {
                type: "supermarket",
                location: userLocation,
                query: formData.pointOfSale,
              },
              callback
            );
          } else {
            placesServiceRef.current?.nearbySearch(
              {
                type: "supermarket",
                location: userLocation,
                keyword: formData.pointOfSale,
                rankBy: google.maps.places.RankBy.DISTANCE,
              },
              callback
            );
          }
        },
        250
      );
    }
  }, [formData.pointOfSale, userLocation]);

  const clearProduct = () => {
    setProductLocked(false);
    setSelectedQuestionMark(null);
    setFormProps({
      barcode: "",
      productName: "",
      product: null,
      manufacturer: "",
    });
  };

  const onProductAutocomplete = (item: QuestionMarkProduct) => {
    setProductLocked(true);
    setFormProps({
      productName: item.name,
      manufacturer: item.brand?.name || item.retailers[0].name,
      product: {
        id: item.id,
        name: item.name,
        // TODO: Maybe take first instead of medium.
        imageUrl: item.image_urls[0]["medium"],
        brand: {
          id: item.brand?.id || item.retailers[0].id + retailerFallbackOffset,
          name: item.brand?.name || item.retailers[0].name,
          logoUrl: item.brand?.logo_url || null,
        },
      },
    });
  };

  const onBarcodeScan = (code: string) => {
    qmRepository
      .findProductByBarcode(code)
      .then((result) => {
        setSelectedQuestionMark(result);
        setFormProps({
          barcode: code,
        });
      })
      .catch(() => {
        setProductLocked(false);
        setFormProps({
          barcode: code,
          productName: "",
          product: null,
          manufacturer: "",
        });
        setTimeout(() => {
          alert(t("add_report.scanner.error_not_found"));
        }, 0);
      })
      .finally(() => setScannerShown(false));
  };

  const onPointOfSaleAutocomplete = (item: google.maps.places.PlaceResult) => {
    var name = item.name || "";
    var vicinity = item.vicinity || item.formatted_address;
    const regex = /(?:\W|^)([^\d\s]{3,})(?=\W|$)/g;
    const matches =
      vicinity?.split(",").map((s) => s.match(regex)?.join(" ") || "") || [];
    const street = matches[0];
    const place = matches[1];

    if (street && !name.includes(street)) {
      name = `${name} ${street}`;
    }

    if (place && !name.includes(place)) {
      name = `${name} ${place}`;
    }

    setFormProps({
      pointOfSale: name,
      googlePlace: {
        placeId: item.place_id || "",
        name: name,
        logo: item.icon || "",
        latitude: item.geometry?.location?.lat() || defaultLocation.lat,
        longitude: item.geometry?.location?.lng() || defaultLocation.lng,
      },
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var { value, name, type, checked } = event.target;
    let parsedValue =
      type === 'number'
        ? value.replace(
            /^(?:0*(?=\d))?(\d{0,})([,\.](?=\d))?(\d{0,2}).*$/,
            "$1$2$3"
          )
        : value;
    setFormProps({ [name]: type === "checkbox" ? checked : parsedValue });
  };

  const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (event.target.files) {
      const convertPromises = Array.from(event.target.files).map((file) =>
        compressImage(file)
      );

      Promise.all(convertPromises).then((files) => {
        setFormProps({ [name]: [...formData.images, ...files] });
      });
    }
  };

  const removeImage = (image: File | Blob) => {
    setFormProps({
      images: formData.images.filter((im) => im !== image),
    });
  };

  useEffect(() => {
    setImgUris(formData.images.map((im) => URL.createObjectURL(im)));
  }, [formData.images]);

  const uploadRef = useRef<HTMLInputElement>(null);
  const triggerFileInput = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  };

  const uploadReport = useCallback(() => {
    setFieldErrors({});
    setIsLoading(true);
    const data = {
      ...formData,
      email: formData.newsletter ? formData.email : "",
      pointOfSale: formData.googlePlace?.name ?? formData.pointOfSale,
      productName: formData.product?.name ?? formData.productName,
      price: formData.price && `${formData.price}`.replace(".", ","),
    };
    const validateResult = DraftReportSchema.safeParse(data);

    if (!validateResult.success) {
      const entries = Object.entries(
        validateResult.error.formErrors.fieldErrors
      );
      const errors = entries
        .filter(([, v]) => v.length > 0)
        .map(([k]) => [k, t(`validation_error.${k}`)]);

      setFieldErrors(Object.fromEntries(errors));

      alert(t("validation_error.generic"));
      setIsLoading(false);
      return;
    }

    draftReportRepository
      .postApiDraftReport(DraftReportSchema.parse(validateResult.data))
      .then(() => {
        navigate("/thank-you");
      })
      .catch((e) => {
        const shouldOpenEmail = window.confirm(
          t("add_report.error.popup_text")
        );

        if (shouldOpenEmail) {
          // Open the mail client with a specific email address
          const emailAddress = t("add_report.error.mail");
          const subject = t("add_report.error.email_subject");
          const body = [
            "Beste foodwatch,",
            "",
            "Ik heb geprobeerd een melding te maken via jullie app, helaas wilde dit niet lukken.",
            "",
            `Product naam: ${data.productName}`,
            `Producent: ${data.manufacturer}`,
            `Winkel: ${data.pointOfSale}`,
            `Type misleiding: ${
              deceptionTypes.find((t) => t.value === data.deception)?.label
            }`,
            "",
            "Melding:",
            data.description,
            "",
            "Ik kreeg de volgende foutmelding:",
            e.message,
            "",
            `Met vriendelijke groet,`,
            data.name,
          ].join("\r\n");

          const queryString = Object.entries({ subject, body })
            .map((kvp) => kvp.map(encodeURIComponent).join("="))
            .join("&");

          window.location.href = `mailto:${emailAddress}?${queryString}`;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [formData]);

  const customStyles = {
    control: (theme: any, state: any) => ({
      ...theme,
      borderRadius: 0,
      borderColor: "transparent",
      boxShadow: state.isFocused ? "0 0 0 2px var(--color-orange-500)" : "none",
      "&:hover": {
        borderColor: "transparent",
      },
    }),
  };

  const customTheme = (theme: any) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: "var(--color-orange-100)",
      primary: "var(--color-orange-500)",
    },
  });

  const questionMarkSelect = (option: QuestionMarkProduct) => {
    let brandName = `${option.brand?.name || option.retailers[0]?.name}`;

    if (option.name.includes(brandName)) {
      return option.name;
    }

    return `${brandName} ${option.name}`;
  };

  return (
    <div className="overlay-container">
      <FWPage>
        <div className="add-report">
          <div className="info">
            <ContentHeader
              title={t("add_report.title")}
              icon={<MegaphoneBoldIcon color="var(--color-orange-500)" />}
            />
            <div className="info-description text-lg">
              {mlt("add_report.description")}
            </div>
          </div>
          <Ketchup className="illustration" />
          <div className="form">
            <h1 className="text-xl-black">{t("add_report.form.title")}</h1>
            <div className="form-item">
              <h2 className="text-base-black">
                {t("add_report.form.productname_label")} *
              </h2>
              <div className="scanButton-container">
                <Select
                  styles={customStyles}
                  theme={customTheme}
                  className="fw-select"
                  classNamePrefix="fw"
                  options={productSuggestions}
                  getOptionLabel={questionMarkSelect}
                  getOptionValue={questionMarkSelect}
                  name={DraftReportProperties.productName}
                  onInputChange={(value) => {
                    setFormProps({ productName: value });
                  }}
                  onChange={(value) => {
                    setSelectedQuestionMark(value);
                  }}
                  placeholder={t("add_report.form.productname_placeholder")}
                  value={selectedQuestionMark}
                  filterOption={() => true}
                />
                {productLocked ? (
                  <div className="clearButton" onClick={clearProduct}>
                    <XBoldIcon />
                  </div>
                ) : (
                  <div
                    className="scanButton"
                    onClick={() => setScannerShown(true)}
                  >
                    <ScanIcon />
                  </div>
                )}
              </div>
              <div className="text-sm qm-attribution">
                Resultaten verstrekt door
                <br />
                <Link to="https://www.thequestionmark.org/" target="_blank">
                  The Questionmark Foundation
                </Link>
              </div>
            </div>
            <div className="form-item">
              <h2 className="text-base-black">
                {t("add_report.form.manufacturer_label")} *
              </h2>
              <Input
                name={DraftReportProperties.manufacturer}
                onChange={(event) => onChange(event)}
                value={formData.manufacturer ?? ""}
                disabled={true}
              />
            </div>
            <div className="form-item">
              <h2 className="text-base-black">
                {t("add_report.form.shop_label")} *
              </h2>
              <Select
                styles={customStyles}
                theme={customTheme}
                className="fw-select"
                classNamePrefix="fw"
                options={pointOfSaleSuggestions}
                getOptionLabel={(option) =>
                  `${option.name} ${
                    option.vicinity || option.formatted_address
                  }`
                }
                getOptionValue={(option) => option.place_id ?? ""}
                name={DraftReportProperties.pointOfSale}
                onInputChange={(value) => {
                  setFormProps({ pointOfSale: value });
                }}
                onChange={(value) => {
                  setSelectedPlaceResult(value);
                }}
                placeholder={t("add_report.form.shop_placeholder")}
                value={selectedPlaceResult}
                filterOption={() => true}
              />
              <div ref={(ref) => setPlacesFooter(ref)}></div>
              {fieldErrors[DraftReportProperties.pointOfSale] && (
                <div className="field-error">
                  {fieldErrors[DraftReportProperties.pointOfSale]}
                </div>
              )}
            </div>
            <div className="form-item">
              <h2 className="text-base-black">
                {t("add_report.form.type_label")}
              </h2>
              <SelectList
                name={DraftReportProperties.deception}
                onChange={(value) => setFormProps({ deception: value })}
                value={formData.deception}
                options={deceptionTypes}
                required={true}
              />
            </div>
            <div className="form-item">
              <h2 className="text-base-black">
                {t("add_report.form.description_label")}
              </h2>
              <Input
                name={DraftReportProperties.description}
                type="textarea"
                rows={5}
                onChange={(event) => onChange(event)}
                value={formData.description ?? ""}
              />
            </div>
            <div className="form-item">
              <h2 className="text-base-black">
                {t("add_report.form.price_label")}
              </h2>
              <Input
                name={DraftReportProperties.price}
                type="number"
                onChange={(event) => onChange(event)}
                value={`${formData.price}`}
              />
            </div>
            <div className="form-item">
              <h2 className="text-base-black">
                {t("add_report.form.photo_label")}
              </h2>
              <div className="file-upload-wrapper">
                <div className="preview-carousel">
                  {imgUris.map((imgUri, i) => {
                    // TODO: Probably style this better
                    return (
                      <img
                        key={imgUri}
                        onClick={() => removeImage(formData.images[i])}
                        src={imgUri}
                      />
                    );
                  })}
                </div>
                <div className="file-upload">
                  <input
                    ref={uploadRef}
                    name="images"
                    type="file"
                    accept="image/jpg, image/jpeg, image/png, image/webp"
                    onChange={(event) => onFileUpload(event)}
                    multiple
                    hidden
                  />
                  <Button
                    className="upload-button"
                    textClassName="text-sm-black"
                    variant={ButtonVariant.secondary}
                    title={t("add_report.form.upload_button_label")}
                    onClick={triggerFileInput}
                  />
                  <div className="text-sm">
                    {formData.images.length > 0
                      ? `${formData.images.length} ${t(
                          "add_report.form.files_chosen_label"
                        )}`
                      : t("add_report.form.no_file_chosen_label")}
                  </div>
                </div>
                <div className="text-sm">
                  {t("add_report.form.allowed_files_label")}
                </div>
              </div>
            </div>
            <div className="form-item">
              <div className="text-base">
                {t("add_report.form.photo_info_label")}
              </div>
            </div>
            <div className="form-item">
              <h2 className="text-base-black">
                {t("add_report.form.name_label")} *
              </h2>
              <Input
                name={DraftReportProperties.name}
                onChange={(event) => onChange(event)}
                value={formData.name}
              />
              {fieldErrors[DraftReportProperties.name] && (
                <div className="field-error">
                  {fieldErrors[DraftReportProperties.name]}
                </div>
              )}
            </div>
            <div className="form-item">
              <CheckBox
                name="newsletter"
                onChange={onChange}
                checked={formData.newsletter}
                label={t("add_report.form.mail_opt_in_label")}
              />
            </div>
            {formData.newsletter && (
              <div className="form-item">
                <h2 className="text-base-black">
                  {t("add_report.form.email_label")}
                </h2>
                <Input
                  name={DraftReportProperties.email}
                  type={"email"}
                  onChange={(event) => onChange(event)}
                  value={formData.email ?? ""}
                />
              </div>
            )}
            <div className="form-item">
              <Button
                title={t("add_report.form.button_label")}
                icon={<ArrowRightBoldIcon className="button-icon" />}
                onClick={uploadReport}
              />
            </div>
          </div>
        </div>
        <>
          {scannerShown && (
            <Scanner
              onScan={onBarcodeScan}
              dismiss={() => setScannerShown(false)}
            />
          )}
        </>
      </FWPage>
      {isLoading && <LoadingIcon isFullScreen={true} />}
    </div>
  );
}

export default AddReportView;
