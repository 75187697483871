import { z } from "zod";

const QuestionMarkBrandSchema = z.object({
  id: z.number(),
  name: z.string(),
  logo_url: z.string().nullable(),
});

const QuestionMarkRetailerSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const QuestionMarkProductSchema = z.object({
  id: z.number(),
  name: z.string(),
  image_urls: z.array(z.record(z.string())),
  brand: QuestionMarkBrandSchema.nullable(),
  retailers: z.array(QuestionMarkRetailerSchema),
});

const ApiQuestionMarkBrandSchema = z.object({
  id: z.number(),
  name: z.string(),
  logoUrl: z.string().nullable(),
});

export const ApiQuestionMarkProductSchema = z.object({
  id: z.number(),
  name: z.string(),
  imageUrl: z.string(),
  brand: ApiQuestionMarkBrandSchema,
});

export type QuestionMarkProduct = z.output<typeof QuestionMarkProductSchema>;
export type ApiQuestionMarkProduct = z.output<
  typeof ApiQuestionMarkProductSchema
>;
