import Compressor from 'compressorjs';

export async function compressImage(file: File | Blob): Promise<File | Blob> {
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      mimeType: 'image/webp',
      maxWidth: 1920,
      maxHeight: 1920,
      
      success(result: File | Blob) {
        resolve(result);
      },
      error(error: Error) {
        reject(error);
      },
    });
  });
}
