import { z } from "zod";
import { ApiImageSchema } from "../image/image-model";
import { ApiQuestionMarkProductSchema } from "../questionmark/questionmark-product";

export const GooglePlaceSchema = z.object({
  placeId: z.string(),
  name: z.string(),
  logo: z.string(),
  latitude: z.number(),
  longitude: z.number(),
});

export const DraftReportSchema = z.object({
  productName: z.string(),
  product: ApiQuestionMarkProductSchema.optional().nullable(),
  manufacturer: z.string().nullable(),
  pointOfSale: z.string().min(1),
  googlePlace: GooglePlaceSchema.optional().nullable(),
  deception: z.string().nullable(),
  description: z.string().nullable(),
  images: z.array(z.instanceof(File).or(z.instanceof(Blob))),
  price: z.string().or(z.number()).nullable(),
  name: z.string().min(1),
  email: z.string().nullable(),
  newsletter: z.boolean(),
});

export const ApiDraftReportSchema = DraftReportSchema.extend({
  id: z.string().uuid(),
  images: z.array(ApiImageSchema),
  creationTime: z.coerce.date(),
});

export const DraftReportProperties = Object.fromEntries(
  DraftReportSchema.keyof().options.map((v) => [v, v])
) as {
  [K in keyof DraftReport]: K;
};

export type GooglePlace = z.output<typeof GooglePlaceSchema>;
export type ApiDraftReport = z.output<typeof ApiDraftReportSchema>;
export type DraftReport = z.output<typeof DraftReportSchema>;
