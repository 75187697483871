import * as React from "react"
import { SVGProps } from "react"
const LocationMarkerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#F7A600"
      fillRule="evenodd"
      d="M2.595 1.72a5.875 5.875 0 0 0 0 8.308L6.44 13.87a.44.44 0 0 0 .621 0l3.844-3.843A5.874 5.874 0 1 0 2.595 1.72Zm1.585 4a2.634 2.634 0 1 1 5.269 0 2.634 2.634 0 0 1-5.269 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default LocationMarkerIcon
