import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useLocation,
  NavLink,
  useNavigation,
  Link,
} from "react-router-dom";
import { HamburgerBoldIcon } from "../../theme/icons/hamburger-bold-icon";
import { ChevronLeftBoldIcon } from "../../theme/icons/chevron-left-bold-icon";
import ArrowRightBoldIcon from "../../theme/icons/arrow-right-bold-icon";
import Button, { ButtonVariant } from "../button";
import { FoodwatchLogo } from "../logo";
import "./header.css";

export enum HeaderTheme {
  Tinted,
  Grey,
}

interface HeaderProps {
  headerTheme: HeaderTheme;
  showBackButton: boolean;
}

function Header({ headerTheme, showBackButton }: HeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const goBack =
    location.key !== "default" ? () => navigate(-1) : () => navigate("/");
  const tintedGoBackTheme = headerTheme === HeaderTheme.Tinted;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [location.pathname]);

  return (
    <div className="header-wrapper">
      <div className="header">
        <FoodwatchLogo className="header-logo" onClick={() => navigate("/")} />
        {isMobile ? (
          <NavLink className="hamburger-menu" to="/menu">
            <HamburgerBoldIcon className="icon" />
          </NavLink>
        ) : (
          <div className="navigation-links">
            <NavLink className="text-lg-black" to="/">
              {t("header.home_label")}
            </NavLink>
            <NavLink className="text-lg-black" to="/statistics">
              {t("header.statistics_label")}
            </NavLink>
            <Link
              className="text-lg-black"
              target="_blank"
              to={t("header.about_target")}
            >
              {t("header.about_label")}
            </Link>
            <Link
              className="text-lg-black"
              target="_blank"
              to={t("header.contact_target")}
            >
              {t("header.contact_label")}
            </Link>
            <NavLink className="text-lg-black button" to="/report">
              {t("header.add_report_label")}
              <ArrowRightBoldIcon />
            </NavLink>
          </div>
        )}
      </div>
      {showBackButton && (
        <div className={`header-back-wrapper ${tintedGoBackTheme && "tinted"}`}>
          <Button
            title={""}
            variant={ButtonVariant.tertiary}
            icon={
              <div className={`back-btn ${tintedGoBackTheme && "tinted"}`}>
                <ChevronLeftBoldIcon
                  className="back-btn-icon"
                  color={
                    tintedGoBackTheme
                      ? "var(--color-dark-500)"
                      : "var(--color-orange-500)"
                  }
                />
                {t("header.back_button")}
              </div>
            }
            onClick={goBack}
          />
        </div>
      )}
    </div>
  );
}

export default Header;
