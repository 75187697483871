import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g fill={props.color ? props.color : "#1F1F1F"}>
      <path d="M6.83 9.813c-.028-.342-.065-.702-.087-1.064-.069-1.176-.124-1.354-.197-2.53C6.43 4.318 6.303 2.415 6.18.512L6.178.483C6.15.165 6.3.006 6.626.006c1.16 0 2.32.006 3.48-.006.25-.002.32.069.303.319-.112 1.875-.211 3.751-.314 5.628-.067 1.223-.135 1.446-.203 2.669-.019.335-.027.673-.066 1.006-.01.088-.122.233-.19.233-.907.013-1.814.004-2.72 0-.017 0-.033-.015-.085-.041l-.001-.001ZM8.286 16c-1.319-.001-2.28-.932-2.286-2.214-.006-1.278.985-2.256 2.29-2.26 1.302-.006 2.29.966 2.29 2.251 0 1.311-.943 2.224-2.295 2.223Z" />
    </g>
  </svg>
);
export { SvgComponent as ExclamationBoldIcon };
