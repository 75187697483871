import * as React from "react";
import "./input.css";

interface InputProps {
  className?: string;
  name: string;
  rows?: number;
  onChange: React.ChangeEventHandler<any>;
  placeholder?: string;
  type?: string;
  value: string;
  disabled?: boolean;
}

const Input = ({
  className = "",
  name,
  rows = 5,
  onChange,
  placeholder = "",
  type = "text",
  value,
  disabled = false,
}: InputProps) => {
  const props = {
    className: `input`,
    "aria-label": name,
    "data-testid": name,
    tabIndex: 0,
    type: type,
    name: name,
    rows: rows,
    onChange: onChange,
    placeholder: placeholder,
    value: value,
    disabled,
  };

  return (
    <div className={`input-wrapper ${className}`}>
      <div className={"input-container"}>
        {props.type === "number" && (
          <>
            <span className={"input-prefix"}>€</span>
            <input {...props} min="0.01" max="10000.00" step="0.01" />
          </>
        )}
        {props.type !== "textarea" && props.type !== "number" && (
          <input {...props} />
        )}
        {props.type === "textarea" && <textarea {...props}></textarea>}
      </div>
    </div>
  );
};

export default Input;
