import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../statistics-page.css";
import DependencyContext from "../../context/dependency-context";
import {
  ApiStatistics,
  StatisticsType,
} from "../../../core/statistics/statistics-model";
import ReportTypes from "../components/report-types";
import ProductStatistics from "../components/product-statistics";
import ReportCta from "../../common/cta/report-cta";
import RecentReports from "../components/recent-reports";
import { LoadingIcon } from "../../common/loading-icon/loading-icon";
import { FWPage } from "../../common/page/page";
import { HeaderTheme } from "../../common/header/header";

const ProductPage = () => {
  const { t } = useTranslation();
  const { statisticsRepository } = useContext(DependencyContext);
  let { id } = useParams();

  const [statistics, setStatistics] = useState<ApiStatistics>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      statisticsRepository
        .getStatistics(id, StatisticsType.product)
        .then((result) => setStatistics(result))
        .then(() => setIsLoading(false));
    }
  }, []);

  return (
    <FWPage headerTheme={HeaderTheme.Tinted}>
      <div className="statistics-overview">
        {statistics ? (
          <>
            <div className="statistic-header">
              <img
                className="logo"
                src={statistics.logo.url}
                alt={`logo ${statistics.name}`}
              />
              <div className="text-2xl-black name">{statistics.name}</div>
            </div>
            <div className="content">
              <ReportTypes items={statistics.deceptionStatistics} />
              <ProductStatistics items={statistics.productStatistics} />
              <RecentReports filter={{ productId: id }} />
              <ReportCta />
            </div>
          </>
        ) : isLoading ? (
          <LoadingIcon />
        ) : (
          <div className="error">
            <div className="text-2xl-black">{t("statistics.error_title")}</div>
            <div className="text-lg">{t("statistics.error")}</div>
          </div>
        )}
      </div>
    </FWPage>
  );
};

export default ProductPage;
