import * as React from "react"
import { SVGProps } from "react"
const MapViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#16282B"
      d="M.037 20.37c0 .463.437.803.885.692l7.546-1.864 6.061 3.03h.062l.103.042h.049l.123.042h.086l.114.02h.569l7.853-2.01a.681.681 0 0 0 .512-.66l-.038-17.737a.628.628 0 0 0-.772-.61l-7.632 1.797L9.689.178 9.485.081h-.046l-.134-.05h-.079L9.103 0h-.335c-.104 0-.209.008-.312.022h-.12l-.17.056L.789 2.014A1.056 1.056 0 0 0 0 3.038L.037 20.37ZM19.729 6.516v10.358l-2.27.567V7.083l2.27-.567Zm-8.851 9.068V5.592l2.269 1.134v9.991l-2.27-1.135v.002ZM6.565 4.869v10.358l-2.27.567V5.438l2.27-.568Z"
    />
  </svg>
)
export default MapViewIcon
