import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#1F1F1F"}
      fillRule="evenodd"
      d="M16 0v14.933H0v-6.4h3.2v5.334h1.067V3.2h3.2v10.667h1.066V5.333h3.2v8.534H12.8V0H16Z"
      clipRule="evenodd"
    />
  </svg>
);
export { SvgComponent as GraphBoldIcon };
