import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#1F1F1F"}
      d="M13.392 12.67c-.066.073-.114.131-.168.185-.678.68-1.359 1.357-2.034 2.04-.12.12-.198.153-.339.013-2.253-2.26-4.511-4.517-6.768-6.774-.039-.039-.072-.082-.083-.095L11.035 1l2.33 2.337c-.983.932-3.584 3.712-4.62 4.696l4.647 4.637Z"
    />
  </svg>
);
export { SvgComponent as ChevronLeftBoldIcon };
