import { IApiClient } from "../api/api-client";
import { ApiSearchResult, ApiSearchResultSchema } from "./search-model";

export interface ISearchRepository {
    getSearchResults(query: string): Promise<ApiSearchResult[]>;
}


export class SearchRepository implements ISearchRepository {
    apiClient: IApiClient;
  
    constructor(apiClient: IApiClient) {
      this.apiClient = apiClient;
    }

    async getSearchResults(query: string) {
        return await this.apiClient.requestItems({
            method: "GET",
            endpoint: "/api/app/report-search",
            body: {
                filter: query,
            }
        }, ApiSearchResultSchema);
    }
}
