import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#1F1F1F"}
      d="M5.412 14.393 0 8.98l2.834-2.833L5.426 8.74 13.166 1 16 3.834 5.412 14.393Z"
    />
  </svg>
);
export { SvgComponent as CheckBoldIcon };
