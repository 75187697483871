import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    height={16}
    width={16}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#1F1F1F"}
      d="m7.971 11.283-4.669 4.7-3.297-3.311 4.688-4.643L0 3.363 3.284.018 7.96 4.696 12.625 0c.108.094.191.162.266.235.968.96 1.93 1.923 2.904 2.876.164.16.158.25 0 .408-1.44 1.43-2.871 2.867-4.306 4.303-.056.056-.107.117-.205.223.432.416.86.815 1.274 1.226 1.08 1.074 2.155 2.155 3.237 3.23.139.137.177.229.014.39-.996.981-1.98 1.974-2.969 2.963-.05.05-.108.09-.177.146l-4.692-4.718v.001Z"
    />
  </svg>
);
export { SvgComponent as XBoldIcon };
