import { z } from "zod";
import { ApiImageSchema } from "../image/image-model";

export const ApiRetailChainSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    logo: ApiImageSchema,
    creationTime: z.coerce.date(),
});

export const ApiPointOfSaleSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    logo: ApiImageSchema,
    latitude: z.number().min(-90).max(90),
    longitude: z.number().min(-180).max(180),
    retailChain: ApiRetailChainSchema.nullable(),
    creationTime: z.coerce.date(),
});

export type ApiRetailChain = z.output<typeof ApiRetailChainSchema>;
export type ApiPointOfSale = z.output<typeof ApiPointOfSaleSchema>;
