import { z } from "zod";

export const ApiValidationError = z.object({
    message: z.string(),
    members: z.array(z.string()),
});

export const ApiErrorSchema = z.object({
    error: z.object({
        code: z.number().nullable(),
        message: z.string().nullable(),
        details: z.string().nullable(),
        data: z.any().nullable(),
        validationErrors: z.array(ApiValidationError),
    })
});

export type ApiError = z.output<typeof ApiErrorSchema>;
