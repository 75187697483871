import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={14}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#1F1F1F"}
      fillRule="evenodd"
      d="M2.966 1.966a6.714 6.714 0 0 0 0 9.494l4.393 4.393a.502.502 0 0 0 .71 0l4.393-4.393a6.714 6.714 0 1 0-9.496-9.494Zm1.811 4.572a3.01 3.01 0 1 1 6.022 0 3.01 3.01 0 0 1-6.022 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export { SvgComponent as PinBoldIcon };
