import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#F0F0F0" rx={5} />
    <path
      fill="#fff"
      d="M32.041 20.004c0 7.187-5.607 13.007-12.525 13.007S6.99 27.184 6.99 20.005 12.598 6.99 19.516 6.99s12.526 5.827 12.526 13.014Z"
    />
    <path
      fill="#1D1D1B"
      d="M20.337 15.358c.63-.333 2.152-.758 3.25-.467 3.15.828 4.036 3.101 4.184 6.273.184 3.895-2.655 7.718-6.535 7.364-.623-.056-1.183-.34-1.728-.623-.545.29-1.104.566-1.728.623-4.007.361-6.181-3.93-6.103-7.944.064-3.25 1.607-6.324 4.85-6.62.857-.079 1.877-.008 2.747.63"
    />
    <path
      fill="#1D1D1B"
      fillRule="evenodd"
      d="M19.515 7.978c-6.336 0-11.537 5.356-11.537 12.025 0 6.67 5.2 12.019 11.537 12.019 6.337 0 11.537-5.342 11.537-12.018 0-6.677-5.2-12.026-11.537-12.026ZM6 20.003C6 12.313 12.015 6 19.515 6c7.5 0 13.515 6.306 13.515 14.003C33.03 27.701 27.014 34 19.515 34 12.015 34 6 27.694 6 20.003Z"
      clipRule="evenodd"
    />
    <path
      fill="#F7A600"
      d="M19.755 17.101c.822-.807 2.032-1.394 3.194-1.281 2.315.233 3.767 2.421 3.717 5.75-.05 3.426-2.924 5.976-5.31 5.763-.68-.064-1.36-.368-1.834-.97-.524.58-1.162.913-1.834.97-2.387.213-4.553-2.337-4.836-5.707-.29-3.363 1.416-6.267 3.802-6.479 1.048-.092 2.464.673 3.101 1.954Z"
    />
    <path
      fill="#1D1D1B"
      d="M17.086 16.859c-.665.085-1.324.75-1.869 1.586-.17.255-.446.319-.673.227-.29-.12-.332-.496-.205-.772.467-.991 1.501-1.87 2.627-2.004a.462.462 0 0 1 .531.361c.064.255-.064.567-.41.61v-.008ZM19.402 14.856s.064-.75.998-2.011c.95-1.622 3.307-2.514 3.307-2.514l-.22 2.492s-1.338-.056-2.18.978c-.786.984-.928 1.904-.928 1.904"
    />
  </svg>
);
export { SvgComponent as AppLogo };
