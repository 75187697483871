import * as React from "react"

const PriceTagIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill="#F7A600"
      d="m5.39 14.845 8.399-4.848a.199.199 0 0 0 .072-.272L9.52 2.205a1.685 1.685 0 0 0-1.174-.82L3.793.599a1.395 1.395 0 0 0-1.545.893L.654 5.826c-.174.47-.128.992.123 1.426l4.342 7.52a.199.199 0 0 0 .272.073ZM4.122 2.951a1.052 1.052 0 1 1 1.052 1.822A1.052 1.052 0 0 1 4.12 2.951Z"
    />
  </svg>
)
export default PriceTagIcon
