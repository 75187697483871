import i18n from 'i18next';
import {UseTranslationOptions, initReactI18next, useTranslation} from 'react-i18next';

import translationNL from './translations/nl.json';
import { $Dictionary } from 'i18next/typescript/helpers';
import { Fragment } from 'react';

export const resources = {
  nl_NL: {
    translation: translationNL,
  },
};
i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: 'nl_NL',
  fallbackLng: 'nl_NL',
  interpolation: {
    escapeValue: false,
  },
});

export const useMlTranslation = (ns?: any, options?: UseTranslationOptions<undefined> | undefined) => {
  const { t } = useTranslation(ns, options);

  const fmt = (s: string, i: number) => {
    return (<Fragment key={i}>
      {s}<br/>
    </Fragment>);
  };
  return { 
    t: t,
    mlt: (key: string) => t(key).split('\n').map(fmt)
  };
}
