import { z } from "zod";

export const SelectStatisticItemSchema = z.object({
    id: z.string(),
    name: z.string(),
    logo: z.object({ id: z.string(), url: z.string() }),
    amount: z.number()
});

export const ApiResponseStatisticsItemsSchema = z.object({
    items: z.array(SelectStatisticItemSchema)
});

export const DeceptionStatisticSchema = z.object({ 
  deceptionType: z.string(), 
  amount: z.number() 
});

export const ProductStatisticSchema = z.object({ 
  id: z.string().uuid(),
  name: z.string(), 
  amount: z.number() 
});

export const ApiStatisticsSchema = z.object({
    name: z.string(),
    logo: z.object({ id: z.string(), url: z.string() }),
    amount: z.number(),
    deceptionStatistics: z.array(DeceptionStatisticSchema),
    productStatistics: z.array(ProductStatisticSchema)
});

export enum StatisticsType {
  pointOfSale = "point-of-sale",
  retailChain = "retail-chain",
  manufacturer = "manufacturer",
  product = "product",
};

export type SelectStatisticItem = z.output<typeof SelectStatisticItemSchema>;
export type ApiResponseStatisticsItems = z.output<typeof ApiResponseStatisticsItemsSchema>;
export type DeceptionStatistic = z.output<typeof DeceptionStatisticSchema>;
export type ProductStatistic = z.output<typeof ProductStatisticSchema>;
export type ApiStatistics = z.output<typeof ApiStatisticsSchema>;
