import { useState } from "react";
import "./image-viewer.css";
import LightBox from "../../common/lightbox/lightbox";

interface ImageViewerProps {
  images: { id: string; url: string }[];
}

function ImageViewer({ images }: ImageViewerProps) {
  const [selectedImage, setSelectedImage] = useState<string|null>(null);

  return (
    <div className="image-viewer">
      <div className="viewer"
        onClick={() => setSelectedImage(images[0].url)}
      >
        <img src={images[0].url} alt={`viewer`} />
      </div>
      <div className="preview-list">
        {images.length > 1 &&
          images.map((image, index) => (
            <div
              className="preview"
              key={image.id}
              onClick={() => setSelectedImage(image.url)}
            >
              <img src={image.url} alt={`preview ${index}`} />
            </div>
          ))}
      </div>
      <LightBox image={selectedImage} onClose={() => setSelectedImage(null)}/>
    </div>
  );
}

export default ImageViewer;
