import { IApiClient } from "../api/api-client";
import { ApiDraftReport, ApiDraftReportSchema, DraftReport } from "./draft-report-model";

export interface IDraftReportRepository {
    postApiDraftReport(report: DraftReport): Promise<ApiDraftReport>;
}


export class DraftReportRepository implements IDraftReportRepository {
    apiClient: IApiClient;
  
    constructor(apiClient: IApiClient) {
      this.apiClient = apiClient;
    }

    async postApiDraftReport(report: DraftReport): Promise<ApiDraftReport> {
        return await this.apiClient.request({
            method: "POST",
            endpoint: "/api/app/draft-report",
            body: report,
        }, ApiDraftReportSchema);
    }
}
