import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g fill={props.color ? props.color : "#1F1F1F"}>
      <path d="M12.318 0a.613.613 0 0 0-.368.128l-4.642 3.54a.84.84 0 0 0-.308.67V7.66c0 .272.115.521.308.669l4.642 3.54a.596.596 0 0 0 .368.131c.377 0 .682-.358.682-.8V.797C13 .358 12.694 0 12.318 0ZM5.397 8.58h.62L6 7.977V3.913H1.06C.477 3.913 0 4.39 0 4.977V7.52c0 .583.477 1.06 1.06 1.06h.993l.92 5.547a.547.547 0 0 0 .54.453h2.234c.16 0 .313-.07.416-.197a.522.522 0 0 0 .12-.44L5.397 8.58ZM13.5 3.748l1.913-.582.291.957-1.913.582zM13.85 7.084l1.865.719-.359.933-1.866-.719z" />
    </g>
  </svg>
);
export { SvgComponent as MegaphoneBoldIcon };
