import { FC } from "react";
import moment from "moment";
import "./report-carousel.css";
import { ApiReport } from "../../../core/report/report-model";
import PriceTagIcon from "./assets/price-tag-icon";
import LocationMarkerIcon from "./assets/location-marker-icon";
import CalendarIcon from "./assets/calendar-icon";
import { useNavigate } from "react-router-dom";

interface ReportCarouselProps {
  reports: ApiReport[];
}

export const ReportCarousel: FC<ReportCarouselProps> = ({ reports }) => {
  const navigate = useNavigate();
  return (
    <div className="report-carousel">
      {reports && reports.map((report: ApiReport) => (
        <div
          className="report"
          key={report.id}
          onClick={() => navigate(`/report/${report.id}`)}
        >
          <img src={report.images[0]?.url || report.product.images[0]?.url} />
          <span className="name">{report.product.name}</span>
          <div className="bottom">
            <span className="manufacturer">
              <PriceTagIcon />
              {report.product.manufacturer?.name}
            </span>
            <span className="place">
              <LocationMarkerIcon />
              {report.pointOfSale.retailChain?.name || report.pointOfSale.name}
            </span>
            <span className="date">
              <CalendarIcon />
              {moment(report.creationTime).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
