import ArrowRightBoldIcon from "../../theme/icons/arrow-right-bold-icon";
import { XBoldIcon } from "../../theme/icons/x-bold-icon";
import Button from "../button";
import { Logo } from "./assets/logo";
import "./toast.css";

interface Props {
  icon?: React.ReactElement;
  text: String;
  onClose: () => void;
  onClick?: () => void;
  hideButton?: boolean;
}

function Toast({ icon, text, onClose, onClick, hideButton }: Props) {
  return (
    <div className="toast">
      <div onClick={onClose}>
        <XBoldIcon />
      </div>
      {icon && <div>{icon}</div>}
      <div className="text-base text" onClick={hideButton ? onClose : onClick}>{text}</div>
      {!hideButton && onClick && (
        <div className="button-container">
          <Button
            title={""}
            icon={<ArrowRightBoldIcon width={24} height={24} />}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
}

export default Toast;
