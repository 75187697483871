import { IApiClient } from "../api/api-client";
import { ApiAutocompleteProduct, ApiAutocompleteProductSchema, ApiProduct, ApiProductSchema } from "./product-model";
import { z } from "zod";

export interface IProductRepository {
    findProductByBarcode(code: string): Promise<ApiAutocompleteProduct>;
    findProducts(name: string): Promise<ApiAutocompleteProduct[]>;
}


export class ProductRepository implements IProductRepository {
    apiClient: IApiClient;
  
    constructor(apiClient: IApiClient) {
      this.apiClient = apiClient;
    }

    async findProductByBarcode(code: string) {
        return await this.apiClient.request({
            method: "POST",
            endpoint: "/api/autocomplete/product/barcode",
            body: {
                barcode: code,
            }
        }, z.array(ApiAutocompleteProductSchema))
        .then(results => {
            if(results.length > 0) {
                return results[0];
            }
            
            throw new Error("Product not found");
        });
    }

    async findProducts(name: string) {
        return await this.apiClient.request({
            method: "POST",
            endpoint: "/api/autocomplete/product/name",
            body: {
                name: name,
            }
        }, z.array(ApiAutocompleteProductSchema));
    }
}
